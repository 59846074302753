import { QueryClient, QueryClientProvider } from 'react-query';
import { UserProvider } from 'contexts/UserContext';
import { SessionProvider } from 'contexts/SessionContext';
import { OrganisationProvider } from 'contexts/OrganisationContext';
import Router from './routes';
import ThemeConfig from './theme';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import ThemePrimaryColor from './components/ThemePrimaryColor';

const queryClient = new QueryClient();

const RoutedApp = () => <Router />;

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeConfig>
        <ThemePrimaryColor>
          <RtlLayout>
            <SessionProvider>
              <UserProvider>
                <OrganisationProvider>
                  <ScrollToTop />
                  <RoutedApp />
                </OrganisationProvider>
              </UserProvider>
            </SessionProvider>
          </RtlLayout>
        </ThemePrimaryColor>
      </ThemeConfig>
    </QueryClientProvider>
  );
}
