import * as React from "react";

function SvgIconGeneralExpenses(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g
        clipPath="url(#icon_general_expenses_svg__clip0_10481_18525)"
        fill={props.fill}
      >
        <path d="M48.3 2H1.093A1.1 1.1 0 000 3.106v6.685a1.1 1.1 0 001.094 1.106h47.205a1.1 1.1 0 001.094-1.106V3.106A1.1 1.1 0 0048.299 2zM17.98 52.918a1.132 1.132 0 00-.97-.565H1.835A1.1 1.1 0 00.74 53.46v6.684a1.1 1.1 0 001.094 1.107H22.39c1.037 0 1.527-1.29.763-1.994a27.349 27.349 0 01-5.161-6.339h-.012zM12.785 39.768h-7.76a1.1 1.1 0 00-1.093 1.106v6.685a1.1 1.1 0 001.093 1.106h10.016c.444 0 .74-.438.592-.853a27.045 27.045 0 01-1.367-6.627c-.068-.795-.695-1.417-1.481-1.417zM15.37 27.172H1.834A1.1 1.1 0 00.74 28.278v6.685a1.1 1.1 0 001.094 1.106h11.474c.58 0 1.048-.438 1.128-1.014a27.37 27.37 0 011.789-6.616.93.93 0 00-.855-1.279v.012zM19.188 23.069a27.285 27.285 0 016.746-6.685c.786-.553.421-1.798-.547-1.798H7.703a1.1 1.1 0 00-1.094 1.106v6.685a1.1 1.1 0 001.094 1.106h10.7c.307 0 .603-.15.785-.415zM41.19 15.854c-12.602 0-22.811 10.326-22.811 23.073S28.588 62 41.189 62C53.792 62 64 51.674 64 38.927S53.791 15.854 41.19 15.854zm6.346 34.31H34.342c-.661 0-1.686-.127-2.085-1.349-.456-1.383.57-2.19 1.07-2.581l.115-.092c1.857-1.476 2.609-2.95 2.563-5.06h-1.538c-.752-.011-1.39-.276-1.8-.738-.376-.426-.513-1.187-.33-1.774.16-.53.695-1.43 2.176-1.372.421.012.843 0 1.287 0v-.253c-.068-.91-.114-1.74-.17-2.57-.251-3.216.968-5.579 3.52-6.835 2.985-1.464 5.982-1.268 8.66.553.58.392.957.945 1.048 1.533.08.53-.069 1.049-.41 1.475-.525.657-1.459 1.245-2.906.3-1.344-.876-2.837-.98-4.455-.323-1.048.427-1.55 1.13-1.538 2.132 0 1.13.08 2.27.16 3.366l.045.691h4.797c1.265.058 2.05.853 2.005 2.029-.045 1.118-.854 1.844-2.062 1.855h-4.546c-.057 1.51-.285 3.366-1.254 5.094h8.842c1.06 0 1.914.876 1.914 1.937 0 1.06-.854 1.936-1.914 1.936v.046z" />
      </g>
      <defs>
        <clipPath id="icon_general_expenses_svg__clip0_10481_18525">
          <path fill="#fff" transform="translate(0 2)" d="M0 0h64v60H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconGeneralExpenses;
