import * as React from "react";

function SvgIconPurchases(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g
        clipPath="url(#icon_purchases_svg__clip0_10481_18545)"
        fill={props.fill}
      >
        <path d="M28.824 54.047c-2.174 0-3.933 1.781-3.933 3.982 0 2.201 1.759 3.983 3.933 3.983s3.933-1.782 3.933-3.983c0-2.2-1.76-3.982-3.933-3.982zM56.168 62.012c2.172 0 3.933-1.783 3.933-3.983s-1.761-3.982-3.933-3.982c-2.173 0-3.934 1.783-3.934 3.982 0 2.2 1.761 3.983 3.934 3.983zM62.364 14.89H39.303V4.703A1.69 1.69 0 0037.622 3c-.93 0-1.681.76-1.681 1.702V14.89H17.204a1.611 1.611 0 01-1.501-1.067l-1.939-5.491c-.773-2.179-2.813-3.631-5.099-3.631H3.768c-1.367 0-2.622.998-2.756 2.371-.157 1.589 1.064 2.916 2.6 2.916h5.053c.068.046.112.08.168.125l12.63 35.672c.773 2.179 2.812 3.631 5.098 3.631h32.7c1.366 0 2.61-.998 2.756-2.371.157-1.589-1.065-2.916-2.6-2.916H27.94a2.128 2.128 0 01-2.006-1.43l-.583-1.656c-.359-1.021.291-2.122 1.356-2.27l32.161-4.493a2.455 2.455 0 002.062-1.962l1.199-6.15 1.838-9.293c.201-1.02-.572-1.974-1.603-1.974v-.011zm-16.002 7.898l-6.589 6.671a2.997 2.997 0 01-2.151.897 3.016 3.016 0 01-2.152-.897l-6.59-6.671a1.707 1.707 0 010-2.406 1.656 1.656 0 012.377 0l4.684 4.743V14.891h3.361v10.234l4.685-4.743a1.656 1.656 0 012.375 0c.661.67.661 1.748 0 2.406z" />
      </g>
      <defs>
        <clipPath id="icon_purchases_svg__clip0_10481_18545">
          <path fill="#fff" transform="translate(1 3)" d="M0 0h63v59H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconPurchases;
