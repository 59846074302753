import * as React from "react";

function SvgIconInterest(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g clipPath="url(#icon_interest_svg__clip0_10481_18670)">
        <path
          d="M30.694 64c-1.702 0-3.076-1.389-3.076-3.11V43.922c0-1.41 1.128-2.55 2.522-2.55h3.824c.881 0 1.712-.61 1.855-1.492.185-1.12-.677-2.104-1.753-2.104H5.076C3.373 37.776 2 36.387 2 34.666V4.11C2 2.389 3.374 1 5.075 1h30.231c1.702 0 3.076 1.389 3.076 3.11v20.45c0 1.462 1.179 2.654 2.624 2.654h19.919c1.701 0 3.075 1.389 3.075 3.11V60.89c0 1.721-1.374 3.11-3.075 3.11H30.694zm11.02-16.19c.02 1.7-.605 2.912-2.122 4.104l-.093.073c-.43.331-1.322 1.036-.922 2.249.39 1.182 1.455 1.182 1.804 1.182h11.092c.933 0 1.681-.767 1.681-1.71 0-.944-.759-1.7-1.681-1.7h-7.3c.76-1.41.944-2.903.995-4.126h3.742c1.056 0 1.763-.643 1.804-1.627.041-1.037-.645-1.742-1.763-1.783h-3.967l-.031-.508c-.062-.974-.133-1.897-.133-2.82 0-.818.4-1.378 1.25-1.72.575-.228 1.128-.352 1.661-.352.707 0 1.374.207 2.01.611.43.28.84.425 1.23.425.502 0 .943-.228 1.312-.694.297-.373.43-.84.358-1.296-.081-.518-.4-.995-.912-1.337-1.26-.85-2.604-1.285-3.998-1.285-1.107 0-2.235.28-3.342.818-2.173 1.068-3.219 3.069-3.003 5.805.05.705.092 1.41.133 2.156v.135h-.994c-1.333 0-1.764.756-1.897 1.202-.164.518-.041 1.192.287 1.565.359.404.912.633 1.569.643h1.22l.01-.01zM27.362 10.443c-.513 0-.984.197-1.343.56l-14.331 14.49a1.936 1.936 0 000 2.726c.358.363.84.56 1.342.56.503 0 .985-.197 1.343-.56l14.332-14.49c.359-.363.553-.85.553-1.358 0-.508-.194-.995-.553-1.358a1.882 1.882 0 00-1.343-.56v-.01zm-.636 13.402c-1.291 0-2.347 1.068-2.347 2.374s1.055 2.373 2.347 2.373 2.348-1.067 2.348-2.373c0-1.306-1.056-2.374-2.348-2.374zM13.707 10.588c-1.292 0-2.348 1.068-2.348 2.373 0 1.306 1.056 2.374 2.348 2.374 1.292 0 2.348-1.068 2.348-2.374s-1.056-2.373-2.348-2.373z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="icon_interest_svg__clip0_10481_18670">
          <path fill="#fff" transform="translate(2 1)" d="M0 0h62v63H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconInterest;
