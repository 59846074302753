import * as React from "react";

function SvgIconLease(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g clipPath="url(#icon_lease_svg__clip0_10481_18573)" fill={props.fill}>
        <path d="M28.228 46.841c0-9.869 7.368-17.991 16.912-19.282a1.862 1.862 0 001.621-1.844V13.331c0-1.107-.424-2.18-1.175-2.994L38.175 2.4A4.431 4.431 0 0034.954 1H6.407A4.395 4.395 0 002 5.392V48.37a4.402 4.402 0 004.408 4.393h20.35c1.198 0 2.047-1.096 1.829-2.267a19.264 19.264 0 01-.348-3.655h-.011zM9.89 9.557h26.88c.937 0 1.688.748 1.688 1.68 0 .933-.751 1.681-1.687 1.681H9.89a1.678 1.678 0 01-1.688-1.68c0-.933.751-1.681 1.687-1.681zm10.872 25.691H9.89a1.678 1.678 0 01-1.687-1.68c0-.934.751-1.682 1.687-1.682h10.872c.936 0 1.687.748 1.687 1.681s-.75 1.681-1.687 1.681zM9.89 24.078a1.678 1.678 0 01-1.687-1.681c0-.933.751-1.681 1.687-1.681h26.88c.937 0 1.688.748 1.688 1.68 0 .933-.751 1.682-1.687 1.682H9.89zM47.948 41.072l-5.659 2.874v8.068h11.667v-8.068l-5.66-2.874a.37.37 0 00-.337 0h-.01z" />
        <path d="M47.784 30.672c-8.957 0-16.216 7.233-16.216 16.159 0 8.925 7.26 16.158 16.216 16.158 8.957 0 16.215-7.233 16.215-16.158 0-8.926-7.258-16.16-16.215-16.16zm10.828 15.432c-.25 0-.5-.054-.74-.173l-.653-.337V52.2a3.079 3.079 0 01-3.08 3.069H42.103a3.079 3.079 0 01-3.08-3.07v-6.604l-.653.337a1.645 1.645 0 01-2.198-.716 1.624 1.624 0 01.718-2.19l9.588-4.86a3.676 3.676 0 013.297 0l7.444 3.775 2.133 1.084a1.627 1.627 0 01-.74 3.08z" />
      </g>
      <defs>
        <clipPath id="icon_lease_svg__clip0_10481_18573">
          <path fill="#fff" transform="translate(2 1)" d="M0 0h62v62H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconLease;
