import * as React from "react";

function SvgIconPrintingStationery(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g
        clipPath="url(#icon_printing_stationery_svg__clip0_10481_18534)"
        fill={props.fill}
      >
        <path d="M46.982 2H18.017a1.41 1.41 0 00-1.408 1.413v13.212c0 .78.63 1.413 1.407 1.413h28.966a1.41 1.41 0 001.407-1.413V3.413c0-.78-.63-1.413-1.407-1.413zM46.982 39.389H18.017c-.78 0-1.408.63-1.408 1.412v20.786c0 .783.628 1.413 1.407 1.413h28.966c.78 0 1.407-.63 1.407-1.413V40.801c0-.782-.628-1.412-1.407-1.412zm-6.798 16.505h-15.37a1.602 1.602 0 01-1.602-1.608c0-.892.715-1.609 1.602-1.609h15.37c.888 0 1.602.718 1.602 1.608 0 .892-.714 1.609-1.602 1.609zm0-7.063h-15.37a1.602 1.602 0 01-1.602-1.608c0-.891.715-1.608 1.602-1.608h15.37c.888 0 1.602.717 1.602 1.608 0 .89-.714 1.608-1.602 1.608z" />
        <path d="M57.482 21.418H7.518C5.58 21.418 4 23.004 4 24.949v19.015c0 1.945 1.58 3.532 3.518 3.532h3.897c.736 0 1.33-.598 1.33-1.337v-7.378a3.073 3.073 0 013.064-3.075h33.404a3.073 3.073 0 013.063 3.075v7.378c0 .739.595 1.337 1.331 1.337h3.897c1.937 0 3.518-1.587 3.518-3.532V24.95c0-1.945-1.58-3.531-3.518-3.531h-.022zm-6.635 6.856a1.865 1.865 0 01-1.862-1.869c0-1.032.834-1.88 1.862-1.88s1.872.837 1.872 1.88a1.86 1.86 0 01-1.872 1.87zm5.271 0a1.865 1.865 0 01-1.861-1.869c0-1.032.833-1.88 1.861-1.88 1.029 0 1.873.837 1.873 1.88a1.86 1.86 0 01-1.873 1.87z" />
      </g>
      <defs>
        <clipPath id="icon_printing_stationery_svg__clip0_10481_18534">
          <path fill="#fff" transform="translate(4 2)" d="M0 0h57v61H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconPrintingStationery;
