import * as React from "react";

function SvgIconInsurance(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g clipPath="url(#icon_insurance_svg__clip0_10481_18666)">
        <path
          d="M57.818 7.24L33.547 1.133a4.191 4.191 0 00-2.094 0L7.183 7.24A2.88 2.88 0 005 10.028v22.273c0 11.993 7.048 22.87 18.014 27.849l7.549 3.419a4.73 4.73 0 003.874 0l7.549-3.42C52.964 55.172 60 44.284 60 32.302V10.028a2.88 2.88 0 00-2.182-2.788zM45.76 26.127L31.053 40.743a3.146 3.146 0 01-2.227.918 3.1 3.1 0 01-2.227-.918l-7.37-7.325a2.507 2.507 0 010-3.562 2.545 2.545 0 013.585 0l6 5.963 13.339-13.255a2.545 2.545 0 013.585 0c.99.985.99 2.578 0 3.563h.022z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="icon_insurance_svg__clip0_10481_18666">
          <path fill="#fff" transform="translate(5 1)" d="M0 0h55v63H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconInsurance;
