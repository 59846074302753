import * as React from 'react';

function SvgIconAdvertisingMarketing(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none">
      <g clipPath="url(#icon_advertising_marketing_svg__clip0_10481_18594)" fill={props.fill}>
        <path d="M11.747 28.488a2.434 2.434 0 00-3.01-1.71c-3.663 1.021-6.674 2.721-7.482 7.077-.83 4.467.344 8.422 4.04 11.244 2.79 2.134 5.71 1.945 8.7 1.1a2.449 2.449 0 001.704-2.977l-3.94-14.745-.012.011zM60.886 24.966c-1.55-6.255-3.475-12.4-6.806-17.977-.919-1.545-2.148-2.978-3.487-4.178-1.383-1.245-2.767-1.011-4.05.367-1.45 1.555-2.822 3.188-4.217 4.788-5.954 6.856-13.17 11.912-21.67 15.067-.908.333-1.87.644-2.833.944a2.693 2.693 0 00-1.75 3.39c.278.855.521 1.633.554 1.755 1.55 5.822 2.878 10.9 4.195 15.933l4.56 17.4a3.394 3.394 0 003.973 2.478c1.915-.389 3.11-2.311 2.623-4.211l-4.604-17.778c.155-.022.299-.033.454-.055h.033c5.733-.778 11.477-.912 17.121.466 4.35 1.067 8.589 2.567 12.872 3.89 2.412.744 3.785.11 4.515-2.334.343-1.178.542-2.422.598-3.645.254-5.544-.753-10.955-2.07-16.3h-.01z" />
      </g>
      <defs>
        <clipPath id="icon_advertising_marketing_svg__clip0_10481_18594">
          <path fill="#fff" transform="translate(1 2)" d="M0 0h62v63H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconAdvertisingMarketing;
