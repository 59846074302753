import * as React from "react";

function SvgIconWater(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g clipPath="url(#icon_water_svg__clip0_10481_18562)">
        <path
          d="M54.936 39.939c-.304-3.88-1.344-7.625-2.803-11.283-2.904-7.328-7.14-13.915-11.986-20.143-1.686-2.175-3.462-4.276-5.288-6.438-1.18-1.397-3.361-1.434-4.604-.087-4.072 4.436-6.101 7.192-9.462 12.16-3.805 5.61-7.09 11.493-9.107 17.956-1.344 4.3-2.143 8.675-1.433 13.173 1.89 11.864 12.633 19.872 24.885 18.586 11.897-1.235 20.686-12.32 19.785-23.937l.013.013zm-22.754 17.14c-8.13-.136-15.296-6.488-16.221-14.397-.077-.68-.153-1.36-.153-2.051 0-1.607 1.193-2.818 2.765-2.843 1.56-.024 2.79 1.162 2.854 2.769.14 4.325 2.144 7.563 5.974 9.713 1.522.853 3.196 1.26 4.946 1.31 1.94.05 3.197 1.186 3.146 2.83-.051 1.631-1.37 2.681-3.31 2.657v.012z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="icon_water_svg__clip0_10481_18562">
          <path fill="#fff" transform="translate(10 1)" d="M0 0h45v63H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconWater;
