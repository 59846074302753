import * as React from "react";

function SvgIconWaste(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g clipPath="url(#icon_waste_svg__clip0_10481_18724)" fill={props.fill}>
        <path d="M57.662 10.517H43.98l-1.619-5.17a4.762 4.762 0 00-4.568-3.337H27.208c-2.112 0-3.946 1.343-4.568 3.337l-1.62 5.17H7.339A2.336 2.336 0 005 12.84c0 1.28 1.05 2.324 2.338 2.324h50.324A2.336 2.336 0 0060 12.841c0-1.28-1.05-2.324-2.338-2.324zm-31.709 0l1.255-3.87h10.584l.107.075 1.19 3.795H25.954zM53.32 19.547H11.681c-1.426 0-2.541 1.247-2.359 2.654l4.3 34.392c.462 3.667 3.593 6.406 7.303 6.406h23.13c3.71 0 6.842-2.75 7.303-6.407l4.3-34.39a2.374 2.374 0 00-2.36-2.655h.022z" />
      </g>
      <defs>
        <clipPath id="icon_waste_svg__clip0_10481_18724">
          <path fill="#fff" transform="translate(5 2)" d="M0 0h55v61H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconWaste;
