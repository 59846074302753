import { useState } from 'react';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  FormControl,
  TextField,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useOrganisations } from 'contexts/OrganisationContext';
import { useUser } from 'contexts/UserContext';

const useStyles = makeStyles((theme) => ({
  userBox: {
    cursor: 'pointer'
  },
  account: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: theme.shape.borderRadiusSm,
    backgroundColor: theme.palette.grey[500_12]
  }
}));

interface OrgOption {
  label: string;
  value: string;
}

export default function UserBox() {
  const { current, currentId, setOrgId: setCurrentId, organisations } = useOrganisations();
  const { name, isSuperAdmin } = useUser();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState<OrgOption>({
    value: currentId ?? '',
    label: current?.name ?? ''
  });

  const classes = useStyles();

  const orgOptions: OrgOption[] = organisations
    ?.sort((a, b) => a.name.localeCompare(b.name))
    .map(({ id, name }) => ({ label: name, value: id }));

  const handleOpen = () => {
    setSelectedOrg({
      value: currentId ?? '',
      label: current?.name ?? ''
    });
    setDialogOpen(true);
  };

  const handleClose = () => setDialogOpen(false);

  const handleCancel = () => {
    setSelectedOrg({
      value: currentId ?? '',
      label: current?.name ?? ''
    });
    setDialogOpen(false);
  };

  const handleSubmit = () => {
    setCurrentId(selectedOrg.value);
    setDialogOpen(false);
  };

  const handleChange = (newValue: OrgOption | null) => {
    if (newValue) {
      setSelectedOrg(newValue);
    }
  };

  return (
    <div>
      <Box sx={{ mb: 2, mx: 2.5 }} onClick={handleOpen} className={classes.userBox}>
        <div className={classes.account}>
          <Avatar alt="My Avatar" src="/static/mock-images/avatars/avatar_default.jpg" />
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              {name}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {current?.name}
            </Typography>
          </Box>
        </div>
      </Box>

      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>Change user data</DialogTitle>
        <DialogContent style={{ width: '400px' }}>
          <DialogContentText marginTop={3} marginBottom={2}>
            {name}
          </DialogContentText>

          <FormControl disabled={!isSuperAdmin} style={{ width: '100%' }}>
            <Autocomplete
              value={selectedOrg}
              options={orgOptions}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value && option.label === value.label
              }
              onChange={(e, value) => handleChange(value)}
              renderOption={(props, option) => (
                <li {...props} key={option.value}>
                  {option.label}
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="Organisation ID" />}
              autoFocus
              fullWidth
              disableClearable
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
