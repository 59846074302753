import * as React from "react";

function SvgIconPension(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g
        clipPath="url(#icon_pension_svg__clip0_10481_18689)"
        fill={props.fill}
      >
        <path d="M62.739 39.497l-.955-.164a2.123 2.123 0 01-1.69-1.541 16.694 16.694 0 00-6.134-9.048c-.527-.393-.867-.994-.867-1.66v-4.48c0-1.05-1.043-1.77-2.041-1.421-1.427.503-3.314 1.475-4.708 3.366a2.117 2.117 0 01-1.8.874c-.208 0-.428-.011-.636-.011H27.36c-8.977 0-16.296 7.026-16.724 15.855h-.022c-.44 0-4.17.076-5.915 1.792a5.758 5.758 0 00-1.68.579c-1.952 1.06-2.545 2.994-1.568 5.169a.826.826 0 001.097.415.815.815 0 00.417-1.082c-.625-1.388-.34-2.415.845-3.06 0 .273.022.558.088.842.307 1.29 1.12 2.152 2.238 2.349 1.054.186 2.162-.273 2.755-1.147.581-.875.538-1.945-.11-2.94-.241-.382-.614-.666-1.053-.863 1.119-.306 2.337-.393 2.897-.404h.022c.274 5.726 3.445 10.686 8.087 13.473.406.24.68.634.746 1.103l.615 4.284a1.463 1.463 0 001.449 1.256h4.07c.736 0 1.361-.546 1.45-1.267l.219-1.672a1.513 1.513 0 011.503-1.311h12.411c.758 0 1.394.557 1.504 1.3l.241 1.705a1.463 1.463 0 001.449 1.256h4.071c.735 0 1.36-.546 1.448-1.267l.461-3.508c.066-.513.406-.95.878-1.18 3.94-1.923 7.012-5.332 8.472-9.495.23-.667.823-1.159 1.514-1.323l1.58-.371a1.52 1.52 0 001.175-1.475v-3.399c0-.732-.527-1.366-1.262-1.497l.01-.032zm-55.22 6.698c-.22.328-.68.514-1.075.448-.57-.098-.812-.656-.922-1.104-.088-.371-.066-.677.066-.94.856-.043 1.602.154 1.81.47.198.306.384.743.121 1.137v-.011zm41.448-7.911a2.472 2.472 0 01-2.48-2.47 2.472 2.472 0 012.48-2.47 2.472 2.472 0 012.48 2.47 2.472 2.472 0 01-2.48 2.47zM34.337 20.385c5.377 0 9.734-4.338 9.734-9.693C44.07 5.338 39.703 1 34.337 1s-9.734 4.338-9.734 9.692c0 5.355 4.357 9.693 9.734 9.693z" />
      </g>
      <defs>
        <clipPath id="icon_pension_svg__clip0_10481_18689">
          <path fill="#fff" transform="translate(1 1)" d="M0 0h63v62H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconPension;
