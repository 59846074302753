import * as React from "react";

function SvgIconDonations(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g
        clipPath="url(#icon_donations_svg__clip0_10481_18605)"
        fill={props.fill}
      >
        <path d="M36.717 48.821a6.871 6.871 0 01-4.212 1.432 6.871 6.871 0 01-4.211-1.432L2 28.591v29.144C2 60.642 4.34 63 7.227 63h50.546C60.66 63 63 60.642 63 57.735V28.592l-26.294 20.23h.01z" />
        <path d="M4.768 26.321l1.956 1.507 2.598 2.003V14.565c0-.377.299-.679.673-.679h45.02c.374 0 .674.302.674.679v15.223l1.293-.98v.021l1.304-1 1.945-1.497a2.17 2.17 0 00.022-3.413l-1.967-1.54v-6.814c0-1.82-1.464-3.295-3.27-3.295h-9.599L34.536 2.7a3.287 3.287 0 00-4.062 0l-10.891 8.56H9.984c-1.806 0-3.27 1.474-3.27 3.294v6.815l-1.967 1.54a2.17 2.17 0 00.021 3.412z" />
        <path d="M25.504 35.59l-.107.087c-.47.366-1.25.969-.887 2.056.363 1.087 1.314 1.055 1.88 1.044.076 0 .129-.01.225 0H38.32c.823 0 1.486-.667 1.486-1.496 0-.83-.663-1.497-1.486-1.497h-8.636c1.069-1.636 1.336-3.456 1.379-5.232h4.457c.962 0 1.582-.549 1.614-1.432.032-.926-.567-1.529-1.582-1.572h-4.66l-.064-.893a52.645 52.645 0 01-.15-3.1c0-1.045.534-1.788 1.593-2.219 1.57-.635 3.035-.527 4.35.323.93.603 1.71.528 2.309-.215.267-.334.374-.732.32-1.141-.075-.463-.374-.894-.844-1.217-2.384-1.604-5.035-1.776-7.696-.484-2.245 1.087-3.314 3.165-3.089 5.986.064.775.107 1.55.15 2.368 0 .172.021.345.032.528h-1.486c-1.144-.033-1.571.549-1.732 1.055-.128.42-.042 1.033.246 1.367.32.366.834.57 1.454.582h1.678c.128 2.163-.61 3.681-2.458 5.135v-.032z" />
      </g>
      <defs>
        <clipPath id="icon_donations_svg__clip0_10481_18605">
          <path fill="#fff" transform="translate(2 2)" d="M0 0h61v61H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconDonations;
