import * as React from "react";

function SvgIconResearchDevelopment(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g clipPath="url(#icon_research_development_svg__clip0_10481_18579)">
        <path
          d="M62.833 18.671c-.42-1.517-1.332-2.588-2.78-3.14-.189.965-.336 1.856-.545 2.726-.19.775-.462 1.517-.693 2.281a30945.408 30945.408 0 00-10.028 33.407c-.252.838-.735 1.495-1.521 1.793-.703.265-1.47.488-2.203.498-11.12.032-22.229.021-33.348.01-.724 0-1.448-.052-2.15-.169-1.291-.223-2.466-.7-3.106-1.994-.797-1.613-.241-2.536 1.532-2.568h24.158c2.843 0 5.696 0 8.54-.042 2.38-.032 4.1-1.103 4.95-3.427.347-.944.756-1.877 1.04-2.832a4323.085 4323.085 0 004.646-15.467L56.927 11c.346-1.156.42-2.323.031-3.49-.734-2.25-2.517-3.47-5.066-3.47-7.784 0-15.557-.02-23.34 0-.997 0-2.004-.052-3.011-.042l-4.71.075c-1.322.042-2.277.71-3.032 2.132L13.897 16.73a15.698 15.698 0 01-.966 2.599L6.45 36.854c-.567 1.56-1.008 2.546-1.679 4.53l-2.213 5.962c-.126.934-.305 1.857-.41 2.8-.083.765-.22 1.55-.115 2.303.62 4.615 5.014 8.54 9.63 8.55 11.204.022 22.396.011 33.6 0 3.304 0 6.388-2.355 7.374-5.558.462-1.496.903-3.003 1.354-4.498 2.884-9.59 5.77-19.191 8.644-28.782.346-1.145.524-2.302.199-3.49zm-37.156-5.134h21.589c1.164 0 1.447.403 1.08 1.527l-.2.616c-.325.954-.89 1.39-1.909 1.39H24.45c-.871 0-1.207-.446-.955-1.284.21-.722.052-.213.294-.913.325-.944.87-1.326 1.867-1.326l.02-.01zM20.527 25.1c.21-.658.03-.17.261-.817.294-.838.86-1.23 1.742-1.23h21.872c.965 0 1.259.403.965 1.357-.22.722-.074.297-.315 1.008-.273.785-.86 1.22-1.689 1.23H21.628c-1.165 0-1.437-.402-1.08-1.527l-.022-.021z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="icon_research_development_svg__clip0_10481_18579">
          <path fill="#fff" transform="translate(2 4)" d="M0 0h61v57H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconResearchDevelopment;
