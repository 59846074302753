import * as React from "react";

function SvgIconConsulting(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g
        clipPath="url(#icon_consulting_svg__clip0_10481_18625)"
        fill={props.fill}
      >
        <path d="M61.62 24.57H49.76c-1.168 0-2.115.95-2.115 2.12v3.5c0 3.389-2.742 6.138-6.124 6.138H25.913c-1.167 0-2.114.95-2.114 2.12v15.3a2.379 2.379 0 002.379 2.385h20.42c.815 0 1.597.32 2.18.906l5.43 5.442c1.102 1.104 2.974.32 2.974-1.236v-3.82c0-.718.573-1.292 1.289-1.292h3.15c1.31 0 2.379-1.07 2.379-2.384V26.955a2.379 2.379 0 00-2.38-2.385zM22.456 14.458a3.352 3.352 0 003.348-3.356 3.352 3.352 0 00-3.348-3.356 3.352 3.352 0 00-3.349 3.356c0 1.854 1.5 3.356 3.349 3.356zM29.56 19.259a31.862 31.862 0 00-7.104-.806 31.23 31.23 0 00-7.104.806c-.11.022-.176.122-.154.243l.694 5.686c.01.11.11.187.22.187h12.72c.111 0 .2-.077.221-.188l.694-5.696c.011-.11-.055-.22-.154-.243l-.033.011z" />
        <path d="M43.9 30.19V3.384A2.379 2.379 0 0041.52 1H3.38A2.386 2.386 0 001 3.385v26.804a2.379 2.379 0 002.379 2.385h2.555c.716 0 1.289.574 1.289 1.292v4.173c0 1.556 1.883 2.34 2.974 1.236l5.782-5.796a3.1 3.1 0 012.18-.905h23.35c1.311 0 2.38-1.07 2.38-2.384h.01zM32.6 19.844l-.695 5.686a3.108 3.108 0 01-3.084 2.738h-12.72a3.117 3.117 0 01-3.085-2.738l-.683-5.697c-.187-1.59.838-3.058 2.38-3.411a37.132 37.132 0 013.656-.63c-1.344-1.18-2.148-2.914-2.148-4.702 0-3.445 2.798-6.25 6.234-6.25 3.437 0 6.234 2.805 6.234 6.25a6.28 6.28 0 01-2.148 4.702c1.267.144 2.5.365 3.668.63a3.091 3.091 0 012.38 3.411l.01.011z" />
      </g>
      <defs>
        <clipPath id="icon_consulting_svg__clip0_10481_18625">
          <path fill="#fff" transform="translate(1 1)" d="M0 0h63v62H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconConsulting;
