import { BoxProps } from '@mui/material';

export default function ErrorIllustration({ ...other }: BoxProps) {
  return (
    <svg
      width="229"
      height="190"
      viewBox="0 0 229 190"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12748_29966)">
        <path
          d="M148.685 176.038H140.295C138.671 176.038 137.265 174.917 136.9 173.34L134.488 163.018C134.122 161.44 132.716 160.32 131.093 160.32H95.7249C94.1017 160.32 92.6957 161.44 92.3299 163.018L89.9179 173.34C89.5521 174.917 88.1461 176.038 86.5229 176.038H78.1325C76.1778 176.038 74.5889 177.627 74.5889 179.581V186.109C74.5889 188.063 76.1778 189.652 78.1325 189.652H148.685C150.64 189.652 152.229 188.063 152.229 186.109V179.581C152.229 177.627 150.64 176.038 148.685 176.038Z"
          fill="#DCE1E3"
        />
        <path
          d="M221.113 55.9086C224.017 55.9086 226.371 53.5543 226.371 50.6503C226.371 47.7462 224.017 45.392 221.113 45.392C218.209 45.392 215.854 47.7462 215.854 50.6503C215.854 53.5543 218.209 55.9086 221.113 55.9086Z"
          fill="#BFC3C6"
        />
        <path
          d="M186.099 181.159C189.003 181.159 191.357 178.805 191.357 175.901C191.357 172.996 189.003 170.642 186.099 170.642C183.195 170.642 180.841 172.996 180.841 175.901C180.841 178.805 183.195 181.159 186.099 181.159Z"
          fill="#BFC3C6"
        />
        <path
          d="M36.5576 180.404C39.045 180.404 41.0614 178.388 41.0614 175.901C41.0614 173.413 39.045 171.397 36.5576 171.397C34.0702 171.397 32.0537 173.413 32.0537 175.901C32.0537 178.388 34.0702 180.404 36.5576 180.404Z"
          stroke="#BFC3C6"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M74.5891 14.7681C78.3139 14.7681 81.3334 11.7486 81.3334 8.02381C81.3334 4.29902 78.3139 1.27948 74.5891 1.27948C70.8643 1.27948 67.8447 4.29902 67.8447 8.02381C67.8447 11.7486 70.8643 14.7681 74.5891 14.7681Z"
          stroke="#BFC3C6"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M221.112 112.127C224.837 112.127 227.857 109.107 227.857 105.382C227.857 101.657 224.837 98.6379 221.112 98.6379C217.388 98.6379 214.368 101.657 214.368 105.382C214.368 109.107 217.388 112.127 221.112 112.127Z"
          stroke="#BFC3C6"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M6.57422 133.948V143.379"
          stroke="#CCCECD"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M11.2842 138.669H1.85352"
          stroke="#CCCECD"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M141.986 5.33746V14.7681"
          stroke="#CCCECD"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M146.708 10.0471H137.266"
          stroke="#CCCECD"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M215.854 138.67V148.1"
          stroke="#CCCECD"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M220.575 143.379H211.133"
          stroke="#CCCECD"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M36.1233 119.911C51.6348 119.911 64.2094 107.337 64.2094 91.825C64.2094 76.3134 51.6348 63.7388 36.1233 63.7388C20.6117 63.7388 8.03711 76.3134 8.03711 91.825C8.03711 107.337 20.6117 119.911 36.1233 119.911Z"
          fill="#8DC153"
        />
        <path
          d="M187.254 32.9321H39.5646C34.9807 32.9321 31.2656 36.6472 31.2656 41.2311V51.2561C31.2656 53.8853 33.4375 55.9429 36.0667 55.9429C36.0781 55.9429 36.101 55.9429 36.1124 55.9429C55.9339 55.9429 71.9945 72.015 71.9945 91.825C71.9945 111.635 55.9225 127.707 36.1124 127.707C36.101 127.707 36.0781 127.707 36.0667 127.707C33.4375 127.707 31.2656 129.765 31.2656 132.394V142.419C31.2656 147.003 34.9807 150.718 39.5646 150.718H187.243C191.826 150.718 195.542 147.003 195.542 142.419V41.2311C195.542 36.6472 191.826 32.9321 187.243 32.9321H187.254Z"
          fill="#DCE1E3"
        />
        <path
          d="M35.2662 98.3865C34.4889 98.3865 33.8373 98.6494 33.3229 99.1867C32.7971 99.7239 32.5342 100.375 32.5342 101.153C32.5342 101.93 32.7971 102.559 33.3344 103.096C33.8716 103.645 34.5118 103.919 35.2662 103.919C36.0207 103.919 36.7065 103.645 37.2438 103.096C37.781 102.547 38.0668 101.896 38.0668 101.153C38.0668 100.41 37.7925 99.7239 37.2438 99.1867C36.6951 98.6494 36.0321 98.3865 35.2662 98.3865Z"
          fill="white"
        />
        <path
          d="M42.6163 82.0629C42.0676 81.217 41.2674 80.5426 40.2043 80.051C39.1413 79.5481 37.861 79.2966 36.3635 79.2966C34.0316 79.2966 32.0083 79.9824 30.2936 81.3542C29.4477 82.0286 29.4477 83.3203 30.2936 83.9833L30.4879 84.1319C31.1281 84.6349 32.0083 84.5663 32.637 84.0519C33.6543 83.206 34.7746 82.7831 35.9863 82.7831C36.8779 82.7831 37.5752 82.9888 38.0896 83.4118C38.604 83.8347 38.8555 84.4177 38.8555 85.1721C38.8555 85.7894 38.684 86.2924 38.3296 86.681C37.9753 87.0697 37.4152 87.5269 36.6379 88.0528C35.9063 88.5557 35.3118 89.013 34.866 89.4359C34.4088 89.8589 34.0201 90.4304 33.6886 91.1392C33.3571 91.8593 33.1971 92.7281 33.1971 93.7569V94.2027C33.1971 95.3229 34.1116 96.2374 35.2318 96.2374C36.3521 96.2374 37.2666 95.3229 37.2666 94.2027V93.9283C37.2666 93.0596 37.4952 92.3737 37.9524 91.8479C38.4097 91.3221 39.107 90.7505 40.0329 90.1447C40.7645 89.6417 41.3589 89.1845 41.8047 88.7729C42.2619 88.3614 42.6506 87.8356 42.9707 87.184C43.2907 86.5324 43.4508 85.7551 43.4508 84.8406C43.4508 83.8347 43.1764 82.9202 42.6277 82.0743L42.6163 82.0629Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_12748_29966">
          <rect
            width="228.29"
            height="189.527"
            fill="white"
            transform="translate(0.709961 0.136353)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
