import * as React from "react";

function SvgIconProfessionalFees(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g
        clipPath="url(#icon_professional_fees_svg__clip0_10481_18540)"
        fill={props.fill}
      >
        <path d="M61.1 11.581H41.194V8.696A3.696 3.696 0 0037.507 5H27.472a3.696 3.696 0 00-3.686 3.696v2.896H3.899A2.904 2.904 0 001 14.499v13.875c0 1.395.98 2.594 2.35 2.853L31.2 36.598a6.998 6.998 0 002.598 0l27.851-5.37A2.894 2.894 0 0064 28.373V14.488c0-1.6-1.293-2.907-2.9-2.907zm-23.572 0H27.461V8.674l10.067.022v2.896-.01z" />
        <path d="M58.966 35.614l-25.168 4.852a6.998 6.998 0 01-2.598 0L6.043 35.614a1.84 1.84 0 00-2.188 1.805v18.673c0 1.6 1.294 2.907 2.9 2.907h51.5c1.594 0 2.899-1.296 2.899-2.907V37.42a1.831 1.831 0 00-2.188-1.805z" />
      </g>
      <defs>
        <clipPath id="icon_professional_fees_svg__clip0_10481_18540">
          <path fill="#fff" transform="translate(1 5)" d="M0 0h63v54H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconProfessionalFees;
