import * as MUI from '@mui/icons-material';
import { NavItemProps } from 'components/NavSection';
import SvgIconStyle from '../../components/SvgIconStyle';
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  trophy: getIcon('ic_trophy'),
  settings: getIcon('ic_code'),
  leaf: getIcon('ic_leaf')
};

const sidebarConfig = (
  isAdmin: boolean,
  isSuperAdmin: boolean
): {
  subheader: string;
  items: NavItemProps[];
}[] => [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Dashboard',
    items: [
      // {
      //   title: 'Account Setup',
      //   path: PATH_DASHBOARD.general.pageAccountSetup,
      //   icon: ICONS.user
      // },
      {
        title: 'Organisation Profile',
        path: PATH_DASHBOARD.general.pageOrganisationProfile,
        icon: <MUI.Business />
      },
      {
        title: 'Business Emissions',
        path: PATH_DASHBOARD.general.pageBusinessEmissions,
        icon: ICONS.analytics
      },
      {
        title: 'Employee Emissions',
        path: PATH_DASHBOARD.general.pageCarbon,
        icon: ICONS.dashboard
      },
      {
        title: 'Challenges & pledges',
        path: PATH_DASHBOARD.general.pageGamification,
        icon: ICONS.trophy
      },
      {
        title: 'Offset & subscription',
        path: PATH_DASHBOARD.general.pageOffset,
        icon: ICONS.leaf
      },
      ...(isAdmin || isSuperAdmin
        ? [
            {
              title: 'Carbon Reports',
              path: PATH_DASHBOARD.general.pageReports,
              icon: <MUI.Feed />
            }
          ]
        : []),
      {
        title: 'Activity-based Calculator',
        path: PATH_DASHBOARD.general.pageCalculator,
        icon: <MUI.Calculate />
      }
    ]
  },
  {
    subheader: '',
    items: [
      {
        title: 'Settings',
        path: '/settings',
        icon: <MUI.Settings />
      },
      {
        title: 'Sign Out',
        path: '/logout',
        icon: <MUI.Logout />
      }
    ]
  }
];

export const signOutOnlySidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      {
        title: 'Sign Out',
        path: '/logout',
        icon: <MUI.Logout />
      }
    ]
  }
];

export default sidebarConfig;
