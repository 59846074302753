import * as React from "react";

function SvgIconTravelNational(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g
        clipPath="url(#icon_travel_national_svg__clip0_10481_18719)"
        fill={props.fill}
      >
        <path d="M59.631 19.515h-.875a.879.879 0 01-.875-.874v-7.76c0-1.834-.886-3.55-2.38-4.606l-3.148-2.25A10.922 10.922 0 0046.036 2H18.964c-2.262 0-4.471.704-6.317 2.026L9.499 6.275a5.663 5.663 0 00-2.38 4.605v7.761c0 .48-.395.874-.875.874h-.875A2.37 2.37 0 003 21.882v9.978a2.37 2.37 0 002.369 2.367h.875c.48 0 .875.395.875.874v15.703c0 2.399 1.814 4.35 4.14 4.627v5.81c0 .98.79 1.77 1.772 1.77h4.684c.982 0 1.772-.79 1.772-1.77v-4.616c0-.629.512-1.13 1.13-1.13h23.776c.63 0 1.131.512 1.131 1.13v4.616c0 .98.79 1.77 1.771 1.77h4.685c.982 0 1.771-.79 1.771-1.77v-5.81c2.327-.277 4.14-2.228 4.14-4.627V35.101c0-.48.395-.874.876-.874a3.241 3.241 0 003.244-3.24v-9.105a2.37 2.37 0 00-2.37-2.367h-.01zM20.81 7.224h23.38a1.812 1.812 0 110 3.624H20.81a1.812 1.812 0 110-3.624zm-.085 41.523c0 .8-.651 1.45-1.452 1.45h-6.562c-.8 0-1.452-.65-1.452-1.45v-2.974c0-.8.651-1.45 1.452-1.45h6.562c.8 0 1.451.65 1.451 1.45v2.974zm33.016 0c0 .8-.651 1.45-1.452 1.45h-6.562c-.8 0-1.451-.65-1.451-1.45v-2.974c0-.8.65-1.45 1.45-1.45h6.563c.8 0 1.452.65 1.452 1.45v2.974zm0-11.407a1.862 1.862 0 01-1.868 1.865H13.127a1.862 1.862 0 01-1.868-1.865V18.822c0-1.034.833-1.865 1.868-1.865h38.746c1.035 0 1.868.831 1.868 1.865V37.34z" />
        <path d="M49.472 20.87H15.527a.555.555 0 00-.554.553V34.74c0 .306.248.554.554.554h33.945a.555.555 0 00.555-.554V21.424a.555.555 0 00-.555-.555z" />
      </g>
      <defs>
        <clipPath id="icon_travel_national_svg__clip0_10481_18719">
          <path fill="#fff" transform="translate(3 2)" d="M0 0h59v61H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconTravelNational;
