import * as React from "react";

function SvgIconTravelInternational(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g clipPath="url(#icon_travel_international_svg__clip0_10481_18715)">
        <path
          d="M37.134 35.505c-.208.174-.382.29-.51.43-4.207 4.393-8.866 8.266-13.71 11.918-1.032.777-1.45 1.693-1.415 2.968.07 2.934-.011 5.855.07 8.789.058 2.203-1.02 3.756-2.596 5.066-.719.603-1.53.325-1.947-.649-.846-1.971-1.67-3.954-2.492-5.925-.522-1.252-1.066-2.504-1.576-3.768-.44-1.101-1.194-1.866-2.284-2.319a1662.948 1662.948 0 01-9.492-4c-1.402-.59-1.518-1.345-.521-2.47 1.414-1.588 3.129-2.272 5.25-2.202 2.863.093 5.725.035 8.577.058.857 0 1.634-.162 2.178-.881 3.953-5.137 7.998-10.168 12.854-14.69-.835-.533-1.553-1.044-2.307-1.473-5.47-3.095-11.335-5.263-17.234-7.362-2.4-.858-4.845-1.6-7.244-2.47-.475-.173-1.054-.626-1.17-1.054-.093-.36.324-.986.672-1.334 2.179-2.133 4.752-2.933 7.823-2.55 10.269 1.298 20.596 1.994 30.934 2.457 1.541.07 2.608-.301 3.732-1.426 2.874-2.852 5.888-5.565 8.936-8.22 1.136-.986 2.526-1.716 3.87-2.458a8.683 8.683 0 012.481-.881c2.735-.533 4.555 1.31 3.813 3.988-.405 1.45-1.02 2.934-1.9 4.14-1.82 2.492-3.848 4.846-5.854 7.2-.904 1.055-1.866 2.11-2.967 2.944-1.877 1.45-2.375 3.2-2.248 5.554.545 9.96 1.17 19.919 2.434 29.82.394 3.12-.36 5.786-2.55 8.058-1.031 1.067-1.877.905-2.388-.51-2.086-5.75-4.08-11.548-6.316-17.24-1.078-2.748-2.573-5.322-3.894-7.966-.267-.545-.66-1.02-1.032-1.565l.023.023z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="icon_travel_international_svg__clip0_10481_18715">
          <path fill="#fff" transform="translate(0 1)" d="M0 0h64v64H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconTravelInternational;
