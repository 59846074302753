import * as React from "react";

function SvgIconAuditAccountancyFees(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g
        clipPath="url(#icon_audit_accountancy_fees_svg__clip0_10481_18599)"
        fill={props.fill}
      >
        <path d="M5.105 59.757c0 2.347 1.9 4.242 4.228 4.242h50.44c2.338 0 4.226-1.906 4.226-4.242v-7.99H5.105v7.99zM59.772 21.057H31.39c-1.284 0-2.437.694-3.107 1.785-3.008 4.87-8.366 8.121-14.482 8.121-2.053 0-4.018-.374-5.84-1.047-1.384-.506-2.856.463-2.856 1.951v13.235H64V25.299c0-2.347-1.9-4.242-4.227-4.242zM54.81 35.603a4.104 4.104 0 01-2.03-.53 2.743 2.743 0 00-2.702 0 4.223 4.223 0 01-2.031.53c-2.657 0-4.743-2.469-4.117-5.246.351-1.542 1.614-2.799 3.15-3.14 1.088-.243 2.12-.066 2.965.407a2.849 2.849 0 002.756 0c.857-.473 1.878-.65 2.965-.407 1.548.341 2.8 1.598 3.15 3.14.627 2.777-1.46 5.246-4.117 5.246h.011z" />
        <path d="M26.593 13.849C26.593 6.752 20.862 1 13.802 1 6.742 1 1 6.752 1 13.849s5.731 12.838 12.802 12.838c7.07 0 12.802-5.752 12.802-12.838h-.01zm-14.526-.926V5.65c0-.958.78-1.74 1.735-1.74.955 0 1.735.782 1.735 1.74v7.273c0 .133.055.254.143.342l3.36 3.372c.68.683.68 1.785 0 2.457-.341.342-.78.507-1.23.507-.45 0-.89-.165-1.23-.506l-3.36-3.373a4.005 4.005 0 01-1.153-2.81v.011z" />
      </g>
      <defs>
        <clipPath id="icon_audit_accountancy_fees_svg__clip0_10481_18599">
          <path fill="#fff" transform="translate(1 1)" d="M0 0h63v63H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconAuditAccountancyFees;
