import * as React from "react";

function SvgIconTaxes(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g clipPath="url(#icon_taxes_svg__clip0_10481_18710)" fill={props.fill}>
        <path d="M47.784 31.672c-8.957 0-16.216 7.233-16.216 16.159 0 8.925 7.26 16.158 16.216 16.158 8.957 0 16.215-7.233 16.215-16.158 0-8.926-7.258-16.16-16.215-16.16zM52.3 55.704h-9.196-.174c-.468 0-1.197-.087-1.48-.943-.326-.977.403-1.54.762-1.812l.087-.065c1.317-1.03 1.86-2.071 1.828-3.546h-1.099c-.533 0-.99-.195-1.284-.52-.261-.293-.36-.835-.24-1.248.12-.368.49-.997 1.546-.954h.914v-.173c-.044-.64-.076-1.226-.12-1.8-.174-2.256.686-3.905 2.503-4.783 2.122-1.02 4.256-.89 6.16.38.414.281.686.66.74 1.073.055.369-.043.737-.294 1.041-.37.466-1.034.868-2.068.217-.957-.607-2.013-.683-3.166-.228-.74.293-1.1.792-1.1 1.497 0 .791.066 1.583.11 2.364l.032.488h3.417c.903.043 1.459.596 1.426 1.42-.033.782-.61 1.291-1.459 1.302h-3.232c-.043 1.052-.196 2.353-.892 3.568h6.29c.751 0 1.36.607 1.36 1.356 0 .748-.609 1.355-1.36 1.355l-.01.011z" />
        <path d="M45.586 11.337L38.175 3.4A4.431 4.431 0 0034.954 2H6.407A4.395 4.395 0 002 6.392V49.37a4.402 4.402 0 004.408 4.393h20.35c1.198 0 2.047-1.096 1.829-2.267a19.264 19.264 0 01-.348-3.655c0-9.869 7.367-17.991 16.912-19.282a1.862 1.862 0 001.621-1.844V14.331c0-1.107-.424-2.18-1.175-2.994h-.01zM20.762 36.248H9.89a1.678 1.678 0 01-1.687-1.68c0-.934.751-1.682 1.687-1.682h10.872c.936 0 1.687.748 1.687 1.681s-.75 1.681-1.687 1.681zm15.998-11.16H9.89a1.678 1.678 0 01-1.687-1.68c0-.933.751-1.681 1.687-1.681h26.88c.937 0 1.688.748 1.688 1.68 0 .933-.751 1.682-1.687 1.682h-.011zm0-11.159H9.89a1.678 1.678 0 01-1.687-1.68c0-.933.751-1.682 1.687-1.682h26.88c.937 0 1.688.749 1.688 1.681 0 .933-.751 1.681-1.687 1.681h-.011z" />
      </g>
      <defs>
        <clipPath id="icon_taxes_svg__clip0_10481_18710">
          <path fill="#fff" transform="translate(2 2)" d="M0 0h62v62H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconTaxes;
