import * as React from "react";

function SvgIconCleaning(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g
        clipPath="url(#icon_cleaning_svg__clip0_10481_18612)"
        fill={props.fill}
      >
        <path d="M32.945 12.61c.088-.694.67-1.21 1.353-1.21h5.707c1.045 0 1.892.863 1.892 1.926v3.84a1.54 1.54 0 001.528 1.557h4.817a1.54 1.54 0 001.528-1.556v-3.673c0-.974.726-1.791 1.672-1.914 6.29-.74 8.226-5.665 8.907-8.532.242-1.052-.527-2.06-1.583-2.06H31.285c-.396 0-.781.101-1.122.303l-3.794 2.194a1.996 1.996 0 00-.99 1.724v2.609c0 .806.473 1.523 1.199 1.836l2.144.918c.561.235.913.829.836 1.456-.297 2.407-1.397 6.426-5.29 9.124-.758.526-1.055 1.545-.604 2.351.319.572.901.885 1.495.885.33 0 .66-.1.957-.291 4.97-3.393 6.433-8.375 6.84-11.498l-.01.01zM7.531 3.34C6.62 1.93 4.75 1.527 3.363 2.456a3.107 3.107 0 00-.868 4.254c.912 1.411 2.782 1.814 4.168.885a3.103 3.103 0 00.868-4.243V3.34zM14.372 2.432a3.103 3.103 0 00-.868 4.244c.912 1.41 2.782 1.814 4.168.884a3.103 3.103 0 00.868-4.243c-.912-1.41-2.782-1.814-4.168-.885zM8.984 11.927a3.103 3.103 0 00-.869 4.243c.913 1.41 2.782 1.814 4.168.884a3.103 3.103 0 00.869-4.243c-.913-1.41-2.783-1.814-4.168-.884zM61.58 39.715L53.796 24.41a3.193 3.193 0 00-2.838-1.758H40.301c-1.352 0-2.44 1.109-2.44 2.486 0 2.956-.969 5.833-2.75 8.173l-4.146 5.441a13.285 13.285 0 00-2.705 8.039v10.032c0 3.963 3.156 7.187 7.06 7.187h20.619c3.893 0 7.06-3.213 7.06-7.187V45.649c0-2.06-.484-4.098-1.419-5.923v-.01z" />
      </g>
      <defs>
        <clipPath id="icon_cleaning_svg__clip0_10481_18612">
          <path fill="#fff" transform="translate(2 1)" d="M0 0h61v63H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconCleaning;
