import * as React from "react";

function SvgIconEntertainment(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g clipPath="url(#icon_entertainment_svg__clip0_10481_18653)">
        <path
          d="M54.837 3H10.163A7.158 7.158 0 003 10.162v44.675A7.158 7.158 0 0010.162 62h44.675A7.158 7.158 0 0062 54.837V10.163A7.158 7.158 0 0054.837 3zM15.024 54.298c0 .664-.54 1.213-1.213 1.213h-2.01a1.214 1.214 0 01-1.213-1.213v-7.65c0-.663.539-1.212 1.212-1.212h2.011c.664 0 1.213.54 1.213 1.213v7.65zm0-17.973c0 .663-.54 1.213-1.213 1.213h-2.01a1.214 1.214 0 01-1.213-1.213v-7.65c0-.663.539-1.213 1.212-1.213h2.011c.664 0 1.213.54 1.213 1.213v7.65zm0-17.974c0 .664-.54 1.213-1.213 1.213h-2.01a1.214 1.214 0 01-1.213-1.213v-7.65c0-.663.539-1.212 1.212-1.212h2.011c.664 0 1.213.539 1.213 1.212v7.65zm25.27 16.315l-12.759 7.256c-1.669.943-3.732-.259-3.732-2.166V25.244c0-1.917 2.063-3.12 3.732-2.166l12.76 7.256c1.679.953 1.679 3.379 0 4.343v-.01zm14.119 19.622c0 .664-.54 1.213-1.213 1.213h-2.011a1.214 1.214 0 01-1.213-1.213v-7.65c0-.663.54-1.212 1.213-1.212h2.01c.664 0 1.214.539 1.214 1.212v7.65zm0-17.973c0 .663-.54 1.212-1.213 1.212h-2.011a1.214 1.214 0 01-1.213-1.212v-7.65c0-.664.54-1.213 1.213-1.213h2.01c.664 0 1.214.539 1.214 1.213v7.65zm0-17.974c0 .663-.54 1.213-1.213 1.213h-2.011a1.214 1.214 0 01-1.213-1.213V10.7c0-.663.54-1.212 1.213-1.212h2.01c.664 0 1.214.539 1.214 1.212v7.65-.01z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="icon_entertainment_svg__clip0_10481_18653">
          <path fill="#fff" transform="translate(3 3)" d="M0 0h59v59H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconEntertainment;
