import * as React from 'react';
import { useQuery } from 'react-query';
import * as Router from 'react-router-dom';
import { getOrganisations, updateOrganisation, Organisation, PatchOrgDto } from 'api/organisation';
import { useSession } from './SessionContext';

export interface OrganisationContextProps {
  isLoading: boolean;
  current: Organisation | null | undefined;
  organisations: Organisation[];
  currentId: string | null | undefined;
  setOrgId: (id: string | null | undefined) => void;
  updateCurrentOrganisation: (updates: PatchOrgDto) => Promise<void>;
}

export const OrganisationContext = React.createContext<OrganisationContextProps>({
  isLoading: false,
  current: {
    id: '',
    name: '',
    targetMembers: -1,
    baselineTargetMembers: -1,
    baselineYear: -1,
    summary: '',
    revenue: -1,
    revenueCurrency: 'GBP',
    logoPath: '',
    domains: []
  },
  currentId: undefined,
  organisations: [],
  setOrgId: (id) => {
    throw new Error('Unimplemented');
  },
  updateCurrentOrganisation: (updates: PatchOrgDto) => {
    throw new Error('Unimplemented');
  }
});

export const OrganisationProvider: React.FC = ({ children }) => {
  const { user, getToken, logout } = useSession();

  const [currentId, setCurrentId] = React.useState<string | null | undefined>(undefined);
  const [organisations, setOrganisations] = React.useState<{
    [id: string]: Organisation;
  }>({});

  const { isLoading, isError, data } = useQuery(['organisations', user?.uid], async () =>
    getOrganisations({
      token: (await getToken())!
    })
  );

  // Important: Perform clean up when a user logs out
  React.useEffect(() => {
    setCurrentId(undefined);
    setOrganisations({});
  }, [logout]);

  React.useEffect(() => {
    if (!isLoading && data) {
      const orgs: { [id: string]: Organisation } = {};
      data.forEach((o) => (orgs[o.id] = o));

      if (orgs && Object.keys(orgs).length > 0 && Object.values(orgs).length > 0) {
        // ToDo: Consider deep comparison
        if (!organisations || Object.keys(organisations).length !== Object.keys(orgs).length) {
          setOrganisations(orgs);
        }

        if (!currentId) {
          const defaultOrgId = Object.values(orgs).filter((org) => org.default)[0]?.id;
          setCurrentId(defaultOrgId);
        }
      }
    } else if (!isLoading) {
      setCurrentId(null);
    }
  }, [isLoading, organisations, data]);

  const updateCurrentOrganisation = React.useCallback(
    async (updates: PatchOrgDto) => {
      const token = await getToken();
      if (!token) {
        const error = new Error('Unable to get user token');
        console.error(error);
        throw error;
      }

      const updatedOrg = await updateOrganisation(currentId!, updates, token);

      setOrganisations((orgs) => ({
        ...orgs,
        [currentId!]: updatedOrg
      }));
    },
    [currentId, getToken]
  );

  const setOrgId = (id: string | null | undefined) => {
    if (id === undefined) {
      setCurrentId(undefined);
    } else if (id === null) {
      setCurrentId(null);
    } else if (
      organisations &&
      Object.keys(organisations).length > 0 &&
      Object.keys(organisations).includes(id!)
    ) {
      setCurrentId(id);
    }
  };

  if (!user) {
    return <>{children}</>;
  }

  if (isError) return <Router.Navigate to="/500" />;

  return organisations &&
    Object.keys(organisations).length > 0 &&
    Object.values(organisations).length > 0 &&
    currentId &&
    currentId.toString().trim() !== '' ? (
    <OrganisationContext.Provider
      value={{
        isLoading,
        current: organisations[currentId],
        organisations: Object.values(organisations),
        currentId,
        setOrgId,
        updateCurrentOrganisation
      }}
    >
      {children}
    </OrganisationContext.Provider>
  ) : (
    <OrganisationContext.Provider
      value={{
        isLoading: isLoading || currentId === undefined,
        current: currentId === undefined ? undefined : null,
        organisations:
          Object.values(organisations) && Object.values(organisations).length > 0
            ? Object.values(organisations)
            : [],
        currentId: currentId === undefined ? undefined : null,
        setOrgId,
        updateCurrentOrganisation
      }}
    >
      {children}
    </OrganisationContext.Provider>
  );
};

export const useOrganisations = () => {
  const context = React.useContext<OrganisationContextProps>(OrganisationContext);

  if (!context)
    throw new Error('useOrganisations must be used from within an organisation context element.');

  return context;
};
