import * as React from "react";

function SvgIconPostage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g clipPath="url(#icon_postage_svg__clip0_10481_18694)">
        <path
          d="M62.751 2.831c-1.282-1.08-2.698-1-4.159-.36a34127.696 34127.696 0 01-55.016 24.21c-1.472.652-2.487 1.608-2.576 3.294-.1 1.934 1.048 3.059 2.788 3.823 3.055 1.35 6.01 2.935 8.876 4.655 1.148.686 1.884.653 2.91-.078 9.723-6.87 24.118-18.16 33.875-24.974a.707.707 0 01.903.09.694.694 0 01-.022 1c-8.207 7.85-21.03 20.207-29.248 28.044-1.093 1.046-1.684 2.182-1.74 3.666v.54l.023 10.266c-.045 2.923.725 4.34 2.643 4.835 1.895.495 3.155-.225 4.616-2.643 2.04-3.373 4.081-6.735 6.222-10.266 4.761 2.485 9.366 4.892 13.983 7.275 2.82 1.462 5.028.45 5.776-2.664 3.746-15.563 7.47-31.125 11.228-46.687.38-1.597.2-2.969-1.082-4.048v.022z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="icon_postage_svg__clip0_10481_18694">
          <path fill="#fff" transform="translate(1 2)" d="M0 0h63v60H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconPostage;
