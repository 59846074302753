import * as React from "react";

function SvgIconEmployeeBenefits(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g
        clipPath="url(#icon_employee_benefits_svg__clip0_10481_18646)"
        fill={props.fill}
      >
        <path d="M9.084 37.855h-6.08c-1.1 0-2.004.954-2.004 2.13V58.87C1 60.046 1.892 61 3.005 61h6.08c1.1 0 2.004-.954 2.004-2.13V39.986c0-1.176-.892-2.13-2.005-2.13zM61.697 39.308a2.812 2.812 0 00-4.009 0L47.963 49.1a2.468 2.468 0 01-1.751.732H31.585a1.691 1.691 0 01-1.685-1.697c0-.931.75-1.697 1.685-1.697H42.93a2.332 2.332 0 002.324-2.34c0-1.286-1.047-2.34-2.324-2.34h-6.74a4.03 4.03 0 01-1.763-.41l-6.344-3.094a4.066 4.066 0 00-1.762-.41h-5.21c-.892 0-1.751.288-2.456.831l-3.426 2.629a3.254 3.254 0 00-1.266 2.573v10.358c0 1.785 1.443 3.238 3.216 3.238h28.713a4.311 4.311 0 003.062-1.276L61.72 43.344a2.859 2.859 0 000-4.036h-.022zM19.878 28.862v-7.73h7.677c.76 0 1.388-.621 1.388-1.397v-6.233c0-.765-.617-1.397-1.388-1.397h-7.677V4.397c0-.765-.616-1.397-1.387-1.397H12.3c-.76 0-1.388.621-1.388 1.397v7.73H3.247c-.76 0-1.388.621-1.388 1.397v6.233c0 .765.617 1.397 1.388 1.397h7.677v7.73c0 .765.617 1.397 1.388 1.397h6.19c.76 0 1.387-.621 1.387-1.397l-.01-.022zM37.467 18.792L47.556 28.95a1.991 1.991 0 002.83 0l10.09-10.158 1.145-1.153c3.304-3.327 3.172-8.794-.397-11.955-3.249-2.883-8.238-2.518-11.3.577l-.958.964-.959-.964c-3.061-3.083-8.05-3.45-11.3-.577-3.569 3.15-3.7 8.628-.396 11.955l1.145 1.153h.011z" />
      </g>
      <defs>
        <clipPath id="icon_employee_benefits_svg__clip0_10481_18646">
          <path fill="#fff" transform="translate(1 3)" d="M0 0h63v58H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconEmployeeBenefits;
