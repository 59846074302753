import * as React from "react";

function SvgIconStaffTraining(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g
        clipPath="url(#icon_staff_training_svg__clip0_10481_18704)"
        fill={props.fill}
      >
        <path d="M32.494 21.027a3.791 3.791 0 003.78-3.788 3.791 3.791 0 00-3.78-3.788 3.791 3.791 0 00-3.78 3.788 3.791 3.791 0 003.78 3.788zM41.15 35.898l.983-7.678a42.813 42.813 0 00-9.627-1.1c-3.23 0-6.474.37-9.576 1.036l.893 7.73h17.34l-.013.012z" />
        <path d="M54.11 1H10.89C8.196 1 6 3.201 6 5.901v53.185c0 2.7 2.196 4.901 4.89 4.901h43.22c2.694 0 4.89-2.2 4.89-4.901V5.9C59 3.201 56.804 1 54.11 1zM21.82 23.69a45.543 45.543 0 014.417-.794 8.485 8.485 0 01-2.183-5.644c0-4.658 3.78-8.446 8.427-8.446s8.427 3.788 8.427 8.446a8.374 8.374 0 01-2.184 5.644c1.494.192 2.975.46 4.418.793 2.298.525 3.805 2.7 3.524 5.042l-.945 7.768c-.28 2.304-2.247 4.057-4.558 4.057H23.811a4.598 4.598 0 01-4.558-4.057l-.945-7.78c-.28-2.355 1.226-4.518 3.524-5.043l-.012.013zM46.78 53.915H18.206a2.335 2.335 0 01-2.324-2.33 2.335 2.335 0 012.324-2.328h28.588a2.335 2.335 0 012.324 2.329c0 1.28-1.047 2.329-2.324 2.329h-.013z" />
      </g>
      <defs>
        <clipPath id="icon_staff_training_svg__clip0_10481_18704">
          <path fill="#fff" transform="translate(6 1)" d="M0 0h53v63H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconStaffTraining;
