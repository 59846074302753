import * as React from "react";

function SvgIconComputerEquipment(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g
        clipPath="url(#icon_computer_equipment_svg__clip0_10481_18620)"
        fill={props.fill}
      >
        <path d="M5.096 3h55.797a3.092 3.092 0 013.096 3.088v38.157a3.092 3.092 0 01-3.096 3.088H5.096A3.092 3.092 0 012 44.245V6.088A3.1 3.1 0 015.096 3zM46.102 56.867h-2.794a1.524 1.524 0 01-1.489-1.173l-.83-3.562a1.524 1.524 0 00-1.49-1.173H26.49c-.712 0-1.327.484-1.489 1.173l-.83 3.562a1.524 1.524 0 01-1.49 1.172h-2.793a1.54 1.54 0 00-1.543 1.54v2.044c0 .85.69 1.538 1.543 1.538H46.09a1.54 1.54 0 001.543-1.538v-2.045c0-.85-.69-1.538-1.543-1.538h.01z" />
      </g>
      <defs>
        <clipPath id="icon_computer_equipment_svg__clip0_10481_18620">
          <path fill="#fff" transform="translate(2 3)" d="M0 0h62v59H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconComputerEquipment;
