import * as React from "react";

function SvgIconBankFees(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g clipPath="url(#icon_bank_fees_svg__clip0_10481_18590)">
        <path
          d="M57.85 21.334h4.222a.928.928 0 00.928-.927v-7.401a.954.954 0 00-.629-.886L33.715 2.204A3.634 3.634 0 0032.5 2c-.416 0-.82.075-1.215.203L2.629 12.12a.933.933 0 00-.629.885v7.4a.94.94 0 00.938.94H7.16c.746 0 1.343.596 1.343 1.343v31.193c0 .747-.597 1.344-1.343 1.344H2.853a.855.855 0 00-.853.853v6.068c0 .47.384.853.853.853h59.294c.47 0 .853-.384.853-.853v-6.068a.856.856 0 00-.853-.853h-4.296a1.338 1.338 0 01-1.343-1.344V22.678c0-.746.597-1.344 1.343-1.344zM22.352 53.701c0 .842-.682 1.525-1.524 1.525h-4.371A1.524 1.524 0 0114.93 53.7V22.859c0-.842.683-1.525 1.525-1.525h4.37c.843 0 1.525.683 1.525 1.525v30.842zm13.859 0c0 .842-.682 1.525-1.525 1.525h-4.37A1.524 1.524 0 0128.79 53.7V22.859c0-.842.682-1.525 1.525-1.525h4.37c.843 0 1.525.683 1.525 1.525v30.842zm13.859 0c0 .842-.683 1.525-1.525 1.525h-4.37a1.524 1.524 0 01-1.525-1.525V22.859c0-.842.682-1.525 1.524-1.525h4.371c.842 0 1.525.683 1.525 1.525v30.842z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="icon_bank_fees_svg__clip0_10481_18590">
          <path fill="#fff" transform="translate(2 2)" d="M0 0h61v61H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconBankFees;
