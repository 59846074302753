import * as React from "react";

function SvgIconRepairs(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g
        clipPath="url(#icon_repairs_svg__clip0_10481_18566)"
        fill={props.fill}
      >
        <path d="M33.65 24.606l2.779 2.793c.145.146.246.304.37.461.459-.112.93-.202 1.41-.202 1.535 0 2.992.608 4.078 1.7l.19.192.864-.867a2.183 2.183 0 012.229-.54c4.615 1.497 9.88.416 13.543-3.267a13.427 13.427 0 003.608-12.288c-.157-.743-1.076-1.025-1.614-.484l-7.718 7.76a2.115 2.115 0 01-3.002 0l-5.12-5.147a2.143 2.143 0 010-3.019l7.719-7.76c.526-.53.28-1.453-.448-1.61-4.56-.992-9.545.439-12.905 4.313-3.126 3.604-3.989 8.549-2.6 12.908a2.21 2.21 0 01-.537 2.24l-2.823 2.84-.023-.023zM26.715 37.985c-.156-.124-.313-.236-.459-.371l-2.778-2.794-19.94 20.06a1.829 1.829 0 000 2.59l4.29 4.314c.717.72 1.871.72 2.577 0l17.99-18.088-.19-.192c-1.49-1.498-1.971-3.604-1.49-5.519zM33.38 31.859a.996.996 0 000-1.397L16.959 13.94a1.401 1.401 0 01-.19-.26l-1.76-3.57a.996.996 0 00-.313-.372l-5.904-4.2a.969.969 0 00-1.266.112L6.07 7.114 4.613 8.578A1.01 1.01 0 004.5 9.851l4.178 5.936c.09.135.224.236.37.315L12.6 17.87c.101.045.18.113.258.192L29.28 34.573a.983.983 0 001.39 0l1.344-1.351 1.344-1.352.023-.011zM37.168 32.402l-5.925 5.957a1.481 1.481 0 000 2.087L53.24 62.562a1.462 1.462 0 002.076 0l5.925-5.957a1.481 1.481 0 000-2.087L39.243 32.402a1.462 1.462 0 00-2.075 0z" />
      </g>
      <defs>
        <clipPath id="icon_repairs_svg__clip0_10481_18566">
          <path fill="#fff" transform="translate(3 2)" d="M0 0h60v61H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconRepairs;
