import * as React from "react";

function SvgIconLightPowerHeating(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g clipPath="url(#icon_light_power_heating_svg__clip0_10481_18680)">
        <path
          d="M57.134 13.674L36.871 2.03a7.8 7.8 0 00-7.743 0L8.866 13.674A7.69 7.69 0 005 20.354v23.292a7.726 7.726 0 003.866 6.68L29.128 61.97a7.801 7.801 0 007.743 0l20.263-11.645A7.69 7.69 0 0061 43.646V20.354a7.726 7.726 0 00-3.866-6.68zm-26.106 3.75a1.93 1.93 0 011.933-1.925 1.93 1.93 0 011.933 1.925v11.645a1.93 1.93 0 01-1.933 1.925 1.93 1.93 0 01-1.933-1.925V17.424zm2.71 29.916c-.288.022-.588.033-.921.033-7.277 0-13.509-5.464-14.486-12.708-.711-5.242 1-9.71 5.065-13.282.411-.365.89-.553 1.389-.553.767 0 1.622.486 1.833 1.56.144.73-.111 1.326-.811 1.946-2.233 1.957-3.488 4.413-3.755 7.31-.5 5.552 3.355 10.673 8.787 11.668.69.133 1.389.188 2.089.188 4.91 0 9.242-3.218 10.531-7.82 1.21-4.346.1-8.172-3.31-11.368-.423-.399-.767-.94-.878-1.405-.178-.752.133-1.393.889-1.869.733-.442 1.533-.365 2.222.199 2.61 2.157 4.299 4.921 5.02 8.206.156.697.234 1.405.312 2.146v.144c.044.298.089.619.133.94-.111 7.84-6.177 14.134-14.097 14.643l-.011.022z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="icon_light_power_heating_svg__clip0_10481_18680">
          <path fill="#fff" transform="translate(5 1)" d="M0 0h56v62H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconLightPowerHeating;
