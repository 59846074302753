import ky from 'ky';

export interface Organisation {
  id: string;
  name: string;
  targetMembers: number;
  baselineTargetMembers: number;
  baselineYear: number;
  summary: string;
  revenue: number;
  revenueCurrency: string;
  logoPath: string;
  domains: OrganisationDomain[];
  default?: Boolean;
}

export interface PatchOrgDto {
  name: string;
  targetMembers: number;
  baselineTargetMembers: number;
  baselineYear: number;
  summary: string;
  revenue: number;
  revenueCurrency: string;
  logoPath: string;
}

export interface OrganisationDomain {
  domain: string;
  autoEnrol: boolean;
}

export interface OrganisationCallData {
  orgId: string | null | undefined;
  token: string;
}

export type JobType = 'pull-expenses' | 'unconfirmed-mappings' | 'classify-expenses';
export type JobStatus = 'PENDING' | 'IN-PROGRESS' | 'ERROR';

export type JobIncomplete = {
  type: JobType;
  status: JobStatus;
  eta: string;
};

export type OrganisationStatus = {
  incompleteJob: JobIncomplete | null;
};

export const getOrganisations = async (data: { token: string }): Promise<Organisation[]> => {
  if (!data || !data.token) {
    return [];
  }

  return ky
    .get(`organisations`, {
      prefixUrl: process.env.REACT_APP_API_URL,
      headers: { Authorization: `Bearer ${data.token}` }
    })
    .then((res) => res.json())
    .then((data) => data.organisations);
};

export const updateOrganisation = (
  id: string,
  updates: PatchOrgDto,
  token: string
): Promise<Organisation> =>
  ky
    .patch(`organisations/${id}`, {
      prefixUrl: process.env.REACT_APP_API_URL,
      headers: {
        Authorization: `Bearer ${token}`
      },
      json: updates
    })
    .then((res) => res.json());

export const uploadOrgLogo = async (
  orgId: string,
  logo: File,
  token: string
): Promise<{ url: string }> => {
  const formData = new FormData();
  formData.append('logo', logo);

  return ky
    .post(`organisations/${orgId}/uploadLogo`, {
      prefixUrl: process.env.REACT_APP_API_URL,
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: formData
    })
    .then((res) => res.json())
    .catch((e) => {
      alert(e.message);
    });
};

export const requestToJoinOrganisation = async (
  token: string,
  userId: string,
  orgId: string
): Promise<void> => {
  try {
    if (!token || !userId || !orgId) {
      throw new Error();
    }

    await ky
      .post(`organisations/joinRequest`, {
        prefixUrl: process.env.REACT_APP_API_URL,
        headers: { Authorization: `Bearer ${token}` },
        json: { userId, orgId }
      })
      .catch((e) => {
        alert('An unexpected error occurred.');
      });
  } catch (e) {
    alert('An unexpected error occurred.');
  }
};

export const createNewOrganisation = async (token: string): Promise<void> => {
  try {
    if (!token) {
      throw new Error();
    }

    await ky
      .post(`organisations/create`, {
        prefixUrl: process.env.REACT_APP_API_URL,
        headers: { Authorization: `Bearer ${token}` }
      })
      .catch((e) => {
        alert('An unexpected error occurred.');
      });
  } catch (e) {
    alert('An unexpected error occurred.');
  }
};

export const getOrganisationStatus = async (
  token: string,
  organisationId: string
): Promise<OrganisationStatus> => {
  if (!token) {
    throw new Error();
  }

  const response: OrganisationStatus = await ky
    .get(`async-jobs/org/status/${organisationId}`, {
      prefixUrl: process.env.REACT_APP_API_URL,
      headers: { Authorization: `Bearer ${token}` }
    })
    .then((res) => res.json())
    .catch((e) => {
      alert('An unexpected error occurred.');
    });

  return response;
};
