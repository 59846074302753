import * as React from "react";

function SvgIconLegal(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g clipPath="url(#icon_legal_svg__clip0_10481_18674)" fill={props.fill}>
        <path d="M46.095 22.55l-.364.695c-1.1 2.102-2.637 6.076-2.637 7.768 0 .82.394 2.208 3.022 2.208 2.627 0 3.022-1.377 3.022-2.208 0-1.598-1.59-5.666-2.69-7.768l-.364-.694h.01zM19.864 22.56l-.363.694c-1.1 2.103-2.638 6.076-2.638 7.769 0 .82.395 2.207 3.022 2.207s3.022-1.377 3.022-2.207c0-1.598-1.589-5.666-2.69-7.769l-.363-.694h.01z" />
        <path d="M57.86 7.244L34.029 1.126a4.253 4.253 0 00-2.056 0L8.139 7.244A2.872 2.872 0 006 10.03v22.276C6 44.3 12.916 55.18 23.685 60.163L31.1 63.59a4.53 4.53 0 003.8 0l7.415-3.427C53.095 55.191 60 44.3 60 32.316V10.03c0-1.314-.883-2.46-2.14-2.786zm-11.744 29.56c-3.926 0-6.563-2.322-6.563-5.792 0-1.093 1.017-5.277 2.606-8.546l.291-.6h-2.461a8.652 8.652 0 01-4.6-1.334l-.624-.39v23.822h6.553c.976 0 1.766.799 1.766 1.787 0 .988-.79 1.787-1.766 1.787H24.672a1.775 1.775 0 01-1.766-1.787c0-.988.79-1.787 1.766-1.787h6.563v-23.81l-.623.388a8.664 8.664 0 01-4.58 1.325h-2.534l.29.599c1.62 3.332 2.649 7.485 2.649 8.546 0 3.46-2.638 5.793-6.563 5.793s-6.563-2.323-6.563-5.793c0-1.913 2.69-11.227 5.826-12.562.155-.063.218-.084.28-.094l.135-.032c.125-.02.228-.031.322-.031h6.148c2.876 0 5.213-2.366 5.213-5.277 0-.989.789-1.788 1.765-1.788.976 0 1.765.8 1.765 1.787 0 2.912 2.337 5.278 5.213 5.278h6.158l.146.02c.374.053.644.158.883.337 3.022 1.945 5.493 10.649 5.493 12.373 0 3.459-2.638 5.792-6.563 5.792l.02-.01z" />
      </g>
      <defs>
        <clipPath id="icon_legal_svg__clip0_10481_18674">
          <path fill="#fff" transform="translate(6 1)" d="M0 0h54v63H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconLegal;
