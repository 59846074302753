import { ReactNode, createContext, useState, useEffect, useContext } from 'react';
import { useSession } from './SessionContext';

type UserContextProps = {
  name: string;
  isAdmin: boolean;
  isSuperAdmin: boolean;
};

const UserContext = createContext<UserContextProps | undefined>(undefined);

type UserProviderProps = {
  children: ReactNode;
};

const UserProvider = ({ children }: UserProviderProps) => {
  const { isSignedIn, user, claims } = useSession();

  const INITIAL_NAME = '';
  const INITIAL_IS_ADMIN = false;
  const INITIAL_IS_SUPER_ADMIN = false;

  const [isAdmin, setIsAdmin] = useState(INITIAL_IS_ADMIN);
  const [isSuperAdmin, setIsSuperAdmin] = useState(INITIAL_IS_SUPER_ADMIN);
  const [name, setName] = useState(INITIAL_NAME);

  useEffect(() => {
    let newIsAdmin = INITIAL_IS_ADMIN;
    let newIsSuperAdmin = INITIAL_IS_SUPER_ADMIN;
    let newName = INITIAL_NAME;

    if (isSignedIn && user && claims) {
      const email = user!.email!;
      newName = user!.displayName ?? email.split('@')[0];
      newIsAdmin = claims?.partner?.admin ?? newIsAdmin;
      newIsSuperAdmin = claims?.partner?.superAdmin ?? newIsSuperAdmin;
    }

    setIsAdmin(newIsAdmin);
    setIsSuperAdmin(newIsSuperAdmin);
    setName(newName);
  }, [isSignedIn, user, claims, INITIAL_IS_ADMIN, INITIAL_IS_SUPER_ADMIN]);

  return (
    <UserContext.Provider
      value={{
        name,
        isAdmin,
        isSuperAdmin
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export { UserProvider, useUser };
