// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    pageOrganisationProfile: path(ROOTS_DASHBOARD, '/org-profile'),
    pageBusinessEmissions: path(ROOTS_DASHBOARD, '/business-emissions'),
    pageCategoryMappings: path(ROOTS_DASHBOARD, '/business-emissions/category-mappings'),
    pageCarbon: path(ROOTS_DASHBOARD, '/carbon'),
    pageGamification: path(ROOTS_DASHBOARD, '/gamification'),
    pageOffset: path(ROOTS_DASHBOARD, '/offset'),
    pageCalculator: path(ROOTS_DASHBOARD, '/calculator'),
    pageReports: path(ROOTS_DASHBOARD, '/reports')
    //pageAccountSetup: path(ROOTS_DASHBOARD, '/account-setup')
  }
};
