import * as React from "react";

function SvgIconRent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g clipPath="url(#icon_rent_svg__clip0_10481_18551)" fill={props.fill}>
        <path d="M20.023 11.271a.611.611 0 00-.236.051l-6.592 3.336v9.548h13.758v-9.548l-6.582-3.335c-.112-.062-.235-.072-.348-.062v.01z" />
        <path d="M60.925 26.798H41.006a2.623 2.623 0 01-2.624-2.612V4.06A3.064 3.064 0 0035.307 1H5.075A3.064 3.064 0 002 4.06v30.082a3.064 3.064 0 003.075 3.06h28.991c1.076 0 1.938.96 1.753 2.071-.143.867-.964 1.469-1.855 1.469H30.14a2.514 2.514 0 00-2.522 2.51V59.95a3.064 3.064 0 003.076 3.06h30.23A3.064 3.064 0 0064 59.95V29.858a3.064 3.064 0 00-3.075-3.06zm-27.402-10.65c-.277.55-.84.898-1.466.898a1.67 1.67 0 01-.738-.174l-1.077-.54v7.895a3.286 3.286 0 01-3.29 3.274H13.205a3.286 3.286 0 01-3.291-3.274v-7.895l-1.076.54a1.648 1.648 0 01-2.204-.714 1.64 1.64 0 01-.103-1.244c.133-.418.43-.755.82-.949l2.563-1.295.338-.174 8.048-4.07c.943-.48 2.04-.53 3.034-.194.174.062.359.102.523.194l8.385 4.244 2.563 1.295c.39.194.677.53.82.949.134.418.103.857-.102 1.244v-.01zm17.94 38.406H40.37c-.36 0-1.415 0-1.805-1.153-.4-1.193.492-1.877.923-2.213l.092-.072c1.517-1.183 2.143-2.366 2.122-4.04h-1.22c-.656-.01-1.21-.234-1.568-.632-.328-.367-.451-1.03-.287-1.54.144-.439.564-1.183 1.897-1.183h1.004v-.133c-.051-.734-.082-1.428-.143-2.122-.216-2.693.82-4.672 3.003-5.712 1.118-.53 2.235-.806 3.342-.806 1.394 0 2.737.428 3.998 1.265.513.337.83.806.913 1.316a1.61 1.61 0 01-.36 1.275c-.368.449-.81.683-1.311.683-.39 0-.8-.143-1.23-.418a3.7 3.7 0 00-2.01-.602c-.533 0-1.087.113-1.66.347-.851.337-1.251.898-1.251 1.693 0 .908.072 1.816.133 2.775l.03.5h3.968c1.107.05 1.794.734 1.763 1.754-.04.97-.748 1.602-1.804 1.602h-3.742c-.05 1.214-.236 2.672-.994 4.06h7.299c.922 0 1.681.755 1.681 1.673s-.759 1.673-1.681 1.673l-.01.01z" />
      </g>
      <defs>
        <clipPath id="icon_rent_svg__clip0_10481_18551">
          <path fill="#fff" transform="translate(2 1)" d="M0 0h62v62H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconRent;
