import * as React from "react";

function SvgIconCostOfGoods(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g
        clipPath="url(#icon_cost_of_goods_svg__clip0_10481_18632)"
        fill={props.fill}
      >
        <path d="M37.578 3.515l-3.81-.481a4.986 4.986 0 00-4.197 1.478L2.311 32.237c-1.75 1.781-1.75 4.66 0 6.43L19.02 55.66a4.408 4.408 0 006.322 0l2.467-2.51V18.247a8.877 8.877 0 013.337-6.956l7.027-5.59c.848-.672.474-2.061-.595-2.196v.011z" />
        <path d="M62.06 14.191l-.22-.18-1.806-1.444-9.56-7.595a4.438 4.438 0 00-5.529 0l-11.576 9.22a5.175 5.175 0 00-1.95 4.065v39.196c0 2.509 2.006 4.547 4.473 4.547h23.625c2.467 0 4.471-2.038 4.471-4.547V18.246c0-1.59-.716-3.08-1.95-4.066l.023.011zm-14.34-3.07c1.432 0 2.6 1.188 2.6 2.644 0 1.457-1.157 2.644-2.6 2.644-1.442 0-2.599-1.187-2.599-2.644 0-1.456 1.157-2.643 2.6-2.643zm6.642 39.734H41.696c-.606.022-1.62.045-1.994-1.12-.385-1.166.452-1.815.948-2.207l.11-.09c1.96-1.557 2.742-3.181 2.61-5.511H41.597c-.65-.011-1.2-.235-1.542-.627-.309-.359-.397-1.009-.264-1.457.165-.537.627-1.165 1.839-1.131.495.011.991 0 1.509 0h.066c0-.19-.022-.381-.033-.56a89.78 89.78 0 00-.154-2.543c-.232-3.036.903-5.254 3.282-6.419 2.82-1.389 5.65-1.21 8.172.516.507.347.826.806.892 1.31.066.426-.055.863-.341 1.221-.639.807-1.465.885-2.445.235-1.399-.907-2.952-1.03-4.626-.347-1.134.46-1.707 1.266-1.696 2.386 0 1.098.077 2.185.154 3.327l.066.963H51.432c1.08.045 1.718.695 1.685 1.692-.033.941-.693 1.535-1.718 1.535h-4.736c-.044 1.904-.33 3.853-1.465 5.612h9.175c.87 0 1.575.717 1.575 1.602 0 .885-.705 1.602-1.575 1.602l-.01.01z" />
      </g>
      <defs>
        <clipPath id="icon_cost_of_goods_svg__clip0_10481_18632">
          <path fill="#fff" transform="translate(1 3)" d="M0 0h63v59H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconCostOfGoods;
