import * as React from "react";

function SvgIconVehicleExpenses(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g
        clipPath="url(#icon_vehicle_expenses_svg__clip0_10481_18684)"
        fill={props.fill}
      >
        <path d="M46.722 1c-9.548 0-17.277 7.732-17.277 17.259 0 9.527 7.74 17.259 17.277 17.259C56.26 35.518 64 27.786 64 18.258 64 8.733 56.27 1 46.722 1zm4.807 25.674H41.54c-.496 0-1.279-.088-1.576-1.014-.353-1.035.43-1.64.805-1.927l.088-.066c1.411-1.102 1.984-2.214 1.94-3.789H41.63c-.574 0-1.059-.21-1.368-.55-.275-.32-.386-.893-.253-1.333.121-.397.518-1.069 1.654-1.025h.97v-.187c-.044-.683-.077-1.3-.132-1.927-.188-2.412.727-4.175 2.668-5.11 2.26-1.091 4.531-.948 6.56.407.441.297.728.704.794 1.145.055.397-.055.782-.309 1.113-.397.495-1.102.925-2.205.23-1.014-.649-2.15-.737-3.374-.242-.794.32-1.18.838-1.169 1.598 0 .837.067 1.696.122 2.522l.033.517h3.627c.96.044 1.555.64 1.522 1.52-.033.837-.65 1.388-1.555 1.388h-3.44c-.044 1.134-.21 2.511-.948 3.81h6.693c.804 0 1.444.65 1.444 1.455a1.46 1.46 0 01-1.456 1.454l.023.01z" />
        <path d="M57.582 40.629a2.128 2.128 0 00-2.921-1.95 21.824 21.824 0 01-7.939 1.487c-5.7 0-10.871-2.18-14.763-5.738a4.48 4.48 0 00-3.021-1.157H12.797c-1.357 0-2.36-1.288-2.018-2.599v-.022c.661-2.456 1.312-4.901 1.985-7.346.75-2.765 3.792-4.01 6.604-4.01h4.3c1.246 0 2.392-.892 2.514-2.125.143-1.42-.982-2.61-2.37-2.61-1.886 0-4.775 0-6.197.022a9.75 9.75 0 00-9.317 7.148c-1.036 3.733-2.018 7.49-3.087 11.212-.165.573-.673 1.355-1.147 1.707-1.94 1.454-2.988 3.381-3.02 5.804a265.613 265.613 0 000 9.373c.054 3.14 1.819 5.551 4.762 6.664.375.143.761.286 1.08.418.332 4.67 2.261 6.906 5.988 7.082 5.579.265 7.464-1.277 8.368-6.927H37.45c.463 4.857 2.458 6.894 6.66 6.927 5.302.044 7.056-1.575 7.662-7.049 4.763-2.026 5.821-3.667 5.821-8.965 0-2.434.022-4.868 0-7.302v-.033l-.01-.011zm-45.954 8.029c-1.875-.011-3.44-1.542-3.495-3.425-.055-1.928 1.521-3.58 3.462-3.613 1.93-.033 3.572 1.564 3.583 3.502 0 1.928-1.62 3.547-3.54 3.536h-.01zm24.344-1.168c-2.227.033-4.454 0-6.67 0s-4.454.022-6.67 0c-1.644-.022-2.669-.914-2.713-2.28-.044-1.431 1.014-2.412 2.723-2.423 4.455-.022 8.898-.022 13.352 0 1.643 0 2.669.915 2.713 2.27.044 1.431-1.015 2.4-2.724 2.422l-.01.011zM46.9 48.658c-1.94-.033-3.518-1.696-3.462-3.613.066-1.938 1.73-3.502 3.66-3.414 1.885.088 3.396 1.663 3.385 3.524-.011 1.928-1.654 3.536-3.583 3.492v.01z" />
      </g>
      <defs>
        <clipPath id="icon_vehicle_expenses_svg__clip0_10481_18684">
          <path fill="#fff" transform="translate(1 1)" d="M0 0h63v63H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconVehicleExpenses;
