import * as React from "react";

function SvgIconEquipment(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g
        clipPath="url(#icon_equipment_svg__clip0_10481_18657)"
        fill={props.fill}
      >
        <path d="M16.942 4.721a.735.735 0 00-1.145.615v8.518c0 .917-.734 1.654-1.645 1.654H8.526a1.646 1.646 0 01-1.645-1.654V5.347c0-.581-.645-.95-1.123-.637a10.417 10.417 0 00.879 18.008c.566.291.933.884.933 1.532v36.33c0 .782.634 1.42 1.412 1.42h4.714c.778 0 1.412-.638 1.412-1.42V24.25c0-.637.355-1.23.922-1.532a10.408 10.408 0 005.648-9.255c0-3.667-1.879-6.886-4.725-8.73l-.011-.012zM27.926 13.91a.956.956 0 01.044.245v18.12a.77.77 0 00.767.772h2.957a.77.77 0 00.767-.771v-18.12a.64.64 0 01.045-.247l.978-2.928a.732.732 0 00.034-.38l-.945-5.567a.766.766 0 00-.756-.638h-3.19a.766.766 0 00-.757.638l-.945 5.567a.743.743 0 00.034.38l.978 2.928h-.011zM33.473 35.44h-6.515c-.626 0-1.134.51-1.134 1.14v24.28c0 .629.508 1.14 1.134 1.14h6.515c.626 0 1.134-.511 1.134-1.14V36.58c0-.63-.508-1.14-1.134-1.14zM63.766 16.37l-6.548-8.775a1.95 1.95 0 00-1.567-.782H40.909a1.17 1.17 0 00-1.167 1.173v9.088a1.17 1.17 0 001.168 1.174H62.82c.967 0 1.512-1.107.934-1.89l.011.012zM51.661 3H47.47a.847.847 0 00-.845.85v.123c0 .469.378.85.845.85h4.191c.467 0 .845-.381.845-.85V3.85c0-.47-.378-.85-.845-.85zM51.615 20.65h-3.658c-.622 0-1.134.492-1.156 1.118L45.49 60.29c-.033.928.7 1.71 1.635 1.71h5.069a1.64 1.64 0 001.634-1.699l-1.045-38.52a1.166 1.166 0 00-1.156-1.13h-.011z" />
      </g>
      <defs>
        <clipPath id="icon_equipment_svg__clip0_10481_18657">
          <path fill="#fff" transform="translate(1 3)" d="M0 0h63v59H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconEquipment;
