import * as React from "react";

function SvgIconStaffExpenses(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g
        clipPath="url(#icon_staff_expenses_svg__clip0_10481_18698)"
        fill={props.fill}
      >
        <path d="M26.402 25.463c6.444 0 11.675-5.256 11.675-11.731C38.077 7.256 32.847 2 26.402 2c-6.444 0-11.685 5.256-11.685 11.732 0 6.475 5.23 11.731 11.674 11.731h.011zM51.174 19.375c-7.636 0-13.826 6.22-13.826 13.894 0 7.673 6.19 13.894 13.826 13.894S65 40.943 65 33.269c0-7.673-6.19-13.894-13.826-13.894zm3.85 20.669h-7.701H47.179h-.154c-.397 0-1.015-.078-1.27-.81-.275-.831.343-1.319.652-1.552l.066-.055c1.126-.887 1.59-1.775 1.556-3.05h-.938c-.452 0-.85-.166-1.092-.443-.221-.255-.31-.72-.199-1.076.1-.321.42-.865 1.324-.82h.784v-.156a53.75 53.75 0 00-.11-1.552c-.155-1.94.584-3.36 2.14-4.114 1.81-.876 3.63-.765 5.252.333.354.233.585.565.64.92.045.322-.044.632-.242.887-.32.4-.883.754-1.766.178-.816-.521-1.721-.588-2.703-.2-.64.255-.938.677-.938 1.286 0 .677.055 1.364.1 2.03l.032.41H53.215c.762.033 1.247.51 1.214 1.22-.022.676-.519 1.109-1.247 1.12h-2.759c-.033.909-.165 2.029-.76 3.071h5.362c.64 0 1.159.521 1.159 1.164 0 .643-.52 1.165-1.16 1.165v.044z" />
        <path d="M33.485 35.72c-.176-1.263-1.159-2.261-2.428-2.317a92.56 92.56 0 00-4.656-.122c-7.448 0-14.698.865-21.66 2.473-2.406.554-3.995 2.872-3.697 5.334L3.052 57.72A4.85 4.85 0 007.863 62h37.076c2.45 0 4.513-1.84 4.81-4.28l.486-4.058c.166-1.375-.75-2.672-2.107-2.883-7.614-1.23-13.595-7.34-14.654-15.047l.011-.011z" />
      </g>
      <defs>
        <clipPath id="icon_staff_expenses_svg__clip0_10481_18698">
          <path fill="#fff" transform="translate(1 2)" d="M0 0h64v60H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconStaffExpenses;
