import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, Button, Typography, Container } from '@mui/material';
// components
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';
import * as Assets from '../assets';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

export interface StatusPageProps {
  code: number;
  title: string;
  message: string;
  illustration?: JSX.Element;
}

export const Status404: StatusPageProps = {
  code: 404,
  title: 'Page Not Found',
  message:
    'Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL?\nBe sure to check your spelling.',
  illustration: <Assets.PageNotFoundIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
};

export const Status500: StatusPageProps = {
  code: 500,
  title: 'Internal Error',
  message: 'Sorry, something has gone wrong on our end!\nOur engineers have been notified.',
  illustration: <Assets.SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
};

export default function StatusPage(props: StatusPageProps) {
  return (
    <RootStyle title={`${props.code} ${props.title}`}>
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                {props.title}
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary' }}>{props.message}</Typography>
            {props.illustration && (
              <motion.div variants={varBounceIn}>{props.illustration}</motion.div>
            )}
            <Button
              to="/"
              size="large"
              variant="contained"
              component={RouterLink}
              sx={{ my: { xs: 5 } }}
            >
              Go to Home
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
