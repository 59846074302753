import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import firebase from 'firebase';
import { useFirebase } from 'hooks/useFirebase';
import LoadingScreen from 'components/LoadingScreen';

interface TokenClaims {
  partner?: {
    admin?: boolean;
    superAdmin?: boolean;
  };
}

interface SessionContextProps {
  getToken: () => Promise<string | null>;
  logout: VoidFunction;
  user: firebase.User | null;
  claims: TokenClaims | null;
  isSignedIn: boolean;
  error: string | null;
}

const SessionContext = createContext<SessionContextProps | undefined>(undefined);

export const SessionProvider: React.FC = ({ children }) => {
  const { app } = useFirebase();

  const [user, setUser] = useState<firebase.User | null>(app.auth().currentUser);
  const [claims, setClaims] = useState<TokenClaims | null>(null);
  const [initialised, setInit] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const unSub = app.auth().onAuthStateChanged(onLoginEvent);

    return () => unSub();
  }, []);

  const onLoginEvent = async (user: firebase.User | null) => {
    console.debug(`User login event: ${user ? 'sign in' : 'sign out'}`);

    if (!user) {
      setUser(null);
      setClaims(null);
      setError(null);
    } else {
      const token = (await user?.getIdTokenResult()) as { claims: TokenClaims };

      // if (!token.claims.partner?.admin) {
      //   await logout();
      //   setError('You do not have permission to access this dashboard');
      //   return;
      // }

      setError(null);
      setUser(user);
      setClaims(token.claims);
    }

    setInit(true);
  };

  const logout: VoidFunction = async () => {
    await app.auth().signOut();

    setUser(null);
    setClaims(null);
    setError(null);
  };

  const getToken = useCallback(async () => (user ? user.getIdToken() : null), [user]);

  return initialised ? (
    <SessionContext.Provider
      value={{
        getToken,
        logout,
        user,
        claims,
        isSignedIn: Boolean(user),
        error
      }}
    >
      {children}
    </SessionContext.Provider>
  ) : (
    <LoadingScreen />
  );
};

export const useSession = () => {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error('useSessionState must be used within a SessionProvider');
  }
  return context;
};
