import * as React from "react";

function SvgIconSoftware(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g
        clipPath="url(#icon_software_svg__clip0_10481_18556)"
        fill={props.fill}
      >
        <path d="M16.497 21.938H4.474c-.806 0-1.474.655-1.474 1.475V55.74C3 58.641 5.357 61 8.255 61h9.717V23.413c0-.807-.656-1.476-1.475-1.476zM61.538 21.938h-36.56c-.806 0-1.474.655-1.474 1.475v37.586h34.24A5.261 5.261 0 0063 55.741V23.413c0-.807-.656-1.476-1.475-1.476h.013zM57.757 3H8.255A5.261 5.261 0 003 8.258v7.01c0 .618.504 1.122 1.122 1.122H61.89c.617 0 1.122-.504 1.122-1.122v-7.01c0-2.9-2.357-5.258-5.256-5.258zm-17.693 8.7a2.082 2.082 0 01-2.08-2.08c0-1.148.933-2.08 2.08-2.08s2.079.932 2.079 2.08c0 1.147-.932 2.08-2.08 2.08zm8.267 0a2.082 2.082 0 01-2.08-2.08c0-1.148.933-2.08 2.08-2.08s2.08.932 2.08 2.08c0 1.147-.933 2.08-2.08 2.08zm8.267 0a2.082 2.082 0 01-2.08-2.08c0-1.148.933-2.08 2.08-2.08s2.08.932 2.08 2.08c0 1.147-.933 2.08-2.08 2.08z" />
      </g>
      <defs>
        <clipPath id="icon_software_svg__clip0_10481_18556">
          <path fill="#fff" transform="translate(3 3)" d="M0 0h60v58H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconSoftware;
