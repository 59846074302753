import * as React from "react";

function SvgIconMaterials(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 65 65" fill="none" >
      <g
        clipPath="url(#icon_materials_svg__clip0_10481_18583)"
        fill={props.fill}
      >
        <path d="M21.864 22.644l7.95-15.183a.297.297 0 000-.303c-.034-.068-.111-.146-.289-.146-.133.011-19.509 2.27-19.509 2.27a3.361 3.361 0 00-2.487 1.607L.056 23.263a.4.4 0 000 .415c.078.135.2.214.345.214l19.841-.247c.678 0 1.3-.394 1.622-1z" />
        <path d="M19.631 27.78l-9.338.101c-.855 0-1.543.72-1.543 1.585v22.263c0 1.551 1.144 2.855 2.665 3.035L30.446 57V14.541l-4.852 9.508c-1.155 2.27-3.442 3.697-5.963 3.72v.01zM64.933 23.262L57.46 10.877a3.341 3.341 0 00-2.487-1.607L35.44 7c-.11 0-.2.056-.255.157a.297.297 0 000 .304l7.961 15.183c.322.607.944.989 1.622 1l19.83.247a.38.38 0 00.345-.213.426.426 0 000-.416h-.011z" />
        <path d="M54.708 27.882l-9.338-.102c-2.521-.022-4.808-1.45-5.963-3.72l-4.852-9.507V57l19.042-2.236c1.521-.18 2.665-1.483 2.665-3.034V29.466c0-.865-.688-1.573-1.543-1.584h-.011z" />
      </g>
      <defs>
        <clipPath id="icon_materials_svg__clip0_10481_18583">
          <path fill="#fff" transform="translate(0 7)" d="M0 0h65v50H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIconMaterials;
