import { Suspense, lazy } from 'react';
import { useRoutes, useLocation } from 'react-router-dom';
// layouts
import { useSession } from 'contexts/SessionContext';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import SignOutOnlyLayout from '../layouts/SignOutOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import { Status404, Status500 } from '../pages/PageStatus';
// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { claims } = useSession();

  const isAdmin = claims?.partner?.admin ?? false;
  const isSuperAdmin = claims?.partner?.superAdmin ?? false;

  return useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '', element: <PageLogin /> },
        { path: '/logout', element: <PageLogout /> },
        { path: '/register', element: <PageSignUp /> }
      ]
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'carbon', element: <PageCarbon /> },
        { path: 'gamification', element: <PageGamification /> },
        { path: 'offset', element: <PageOffset /> },
        { path: 'calculator', element: <PageCalculator /> },
        {
          path: 'business-emissions/category-mappings',
          element: <PageCategoryMappings />
        },
        {
          path: 'business-emissions',
          element: <PageBusinessEmissions />
        },
        { path: 'org-profile', element: <PageOrganisationProfile /> },
        ...(isAdmin || isSuperAdmin ? [{ path: 'reports', element: <PageReports /> }] : [])

        // { path: 'account-setup', element: <PageAccountSetup /> }
      ]
    },
    {
      path: '/settings',
      element: <DashboardLayout />,
      children: [{ path: '', element: <PageSettings /> }]
    },
    {
      path: '/connect/:id',
      element: <ExchangeConnectPage />
    },
    {
      path: '/error',
      element: <SignOutOnlyLayout />,
      children: [{ path: '', element: <PageError /> }]
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <StatusPage {...Status404} /> },
        { path: '500', element: <StatusPage {...Status500} /> },
        { path: '*', element: <StatusPage {...Status404} /> }
      ]
    },
    { path: '*', element: <StatusPage {...Status404} /> }
  ]);
}

// IMPORT COMPONENTS

// Dashboard
const PageCarbon = Loadable(lazy(() => import('../pages/PageCarbonSummary')));
const PageGamification = Loadable(lazy(() => import('../pages/PageChallenges')));
const PageOffset = Loadable(lazy(() => import('../pages/PageOffset')));
const PageCalculator = Loadable(lazy(() => import('../pages/PageCalculator')));
const PageLogin = Loadable(lazy(() => import('../pages/PageLogin')));
const PageLogout = Loadable(lazy(() => import('../pages/PageLogout')));
const PageSignUp = Loadable(lazy(() => import('../pages/PageSignUp')));
const PageSettings = Loadable(lazy(() => import('../pages/PageSettings')));
const StatusPage = Loadable(lazy(() => import('../pages/PageStatus')));
const ExchangeConnectPage = Loadable(lazy(() => import('../pages/PageExchangeConnect')));
const PageBusinessEmissions = Loadable(lazy(() => import('../pages/PageBusinessEmissions')));
const PageCategoryMappings = Loadable(lazy(() => import('../pages/PageCategoryMappings')));
const PageOrganisationProfile = Loadable(lazy(() => import('../pages/PageOrganisationProfile')));
const PageReports = Loadable(lazy(() => import('../pages/PageReports')));
const PageError = Loadable(lazy(() => import('../pages/PageError')));
// const PageAccountSetup = Loadable(lazy(() => import('../pages/AccountSetup/PageAccountSetup')));
